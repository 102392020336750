import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import { AccountPermissionsReport } from "./List"
import AccountPermission from "../../models/AccountPermission";
import { HttpAccountPermissionsItemReport } from "./Item";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountPermissionsReport
}

interface ActionProp {
  id: string;
  show: string;
}

function AccountPermissionsPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [actions, setActions] = useState<ActionProp[] | []>([])
  const [availableActions, setAvailableActions] = useState<ActionProp[]>([])
  const [selectedAction, setSelectedAction] = useState<string>('')
  const [newPermissionData, setNewPermissionData] = useState({
    name: '',
    actions: [] as ActionProp[]
  })

  const [mode, setMode] = useState(false)
  const [modeActions, setModeActions] = useState<ActionProp[]>([])
  const [checkedActions, setCheckedActions] = useState<string[]>([])
  const [searchActions, setSearchActions] = useState('')

  const [permissionsRows, setPermissionsRows] = useState<AccountPermission[] | []>([])
  const [selectedPermissionRow, setSelectedPermissionRow] = useState('')

  // save document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.post(`/permissions/accounts`, qs.stringify({
        account_id: activeAccountId,
        name: newPermissionData.name,
        actions: mode ? JSON.stringify(checkedActions) : JSON.stringify(newPermissionData.actions?.map(item => item.id))
      }))
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('accounts_permissions', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: permissionsData, success, error } } = (await httpClientUpdate.post('/permissions/accounts/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 50,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (permissionsData.permissions.account_permission_add) {
          setActions(permissionsData.edit.actions)
          setAvailableActions(permissionsData.edit.actions)
          setSelectedAction("")

          setModeActions(permissionsData.edit.actions.map(item => item))

          setPermissionsRows(permissionsData.permissions_rows)

          setTimeout(() => setReadyToSave(false), 100)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load accountPermission data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (actions) {
      let updatedActions = actions.map(item => item)

      setModeActions(updatedActions.filter(item => checkedActions.includes(item.id) || item.show.toLowerCase().includes(searchActions.toLowerCase())))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActions])

  useEffect(() => {
    if (newPermissionData.actions && actions) {
      if (mode) {
        setCheckedActions(newPermissionData.actions.map(item => item.id))

        setSearchActions('')
      } else {
        let updateActions = actions.filter(item => checkedActions.includes(item.id))

        setNewPermissionData({
          ...newPermissionData,
          actions: updateActions
        })

        let _availableActions = actions.filter(action => !(updateActions as ActionProp[]).filter(item => item.id === action.id).length && action)
        _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

        setAvailableActions([
          ..._availableActions
        ])

        setSelectedAction('')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  // Loading permissions
  async function loadingPermissions() {
    try {
      setIsSaving(true)
      const { data: { data: permissionData, success, error } } = await httpClientUpdate.get('/permissions/accounts/' + selectedPermissionRow, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpAccountPermissionsItemReport }
      if (success) {
        let _availableActions = permissionData.edit.actions.filter(action => !permissionData.actions.filter(item => item.id === action.id).length && action)

        setAvailableActions(_availableActions)
        setSelectedAction('')
        setNewPermissionData({
          ...newPermissionData,
          actions: permissionData.actions
        })

        setSelectedPermissionRow('')
        setIsSaving(false)
      } else {
        setIsSaving(false)
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Add action function
  function addAction() {

    if (!newPermissionData.actions || !newPermissionData) return

    let updateActions = newPermissionData.actions.map(item => item)
    actions.forEach(item => item.id === selectedAction && updateActions.push(item))
    setNewPermissionData({
      ...newPermissionData,
      actions: updateActions
    })

    let _availableActions = actions.filter(action => !(newPermissionData.actions as ActionProp[]).filter(item => item.id === action.id).length && action)
    _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

    setAvailableActions([
      ..._availableActions
    ])

    setSelectedAction('')

    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _permissionActions = [...(newPermissionData.actions as ActionProp[])]

    let _action = _permissionActions[i]

    _permissionActions.splice(i, 1)

    setNewPermissionData({
      ...newPermissionData,
      actions: _permissionActions
    })

    setAvailableActions([
      ...availableActions,
      _action
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newPermissionData
  ])

  function handleChangeMode() {
    setMode(!mode)
  }

  function handleChangeModeActions(id: string) {
    if (checkedActions.includes(id)) {
      setCheckedActions(checkedActions.filter(item => item !== id))
    } else {
      let updatedCheckedActions = checkedActions.map(item => item)
      updatedCheckedActions.push(id)
      setCheckedActions(updatedCheckedActions)
    }

    setReadyToSave(true)
  }

  // Render function
  return (<>
    {availableActions ? (
      <div className="AccountPermissionsPage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>New Account`s Permission</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('accounts_permissions', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input type="text" onChange={({ target: { value } }) => setNewPermissionData({ ...newPermissionData, name: value })} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="flex-container sb wrap">
            <div className="legend">Actions</div>

            <div className="legend-action-wrapper">
              <button
                style={{ width: 'fit-content', backgroundColor: 'inherit' }}
                onClick={() => handleChangeMode()}
              >
                <Icon
                  style={{
                    width: '24px',
                    height: '24px',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    fill: mode ? 'inherit' : '#6093DE'
                  }}
                  viewBox="0 0 1024 1024"
                  icon="change-1"
                />
              </button>

              <label>Action:</label>

              {
                mode ?
                  <div>
                    <input
                      type="text"
                      value={searchActions}
                      onChange={({ target: { value } }) => setSearchActions(value)}
                    />
                  </div> :
                  <div style={{ zIndex: '7' }} className="input-wrapper">
                    <Select
                      options={availableActions.map((action) => ({
                        span: action.show,
                        value: action.id
                      }))}
                      selectedOption={selectedAction}
                      onChange={(value) => setSelectedAction(value as string)}
                    />

                    <button disabled={!selectedAction} className="_green" onClick={() => addAction()}>
                      Add
                    </button>
                  </div>
              }
            </div>
          </div>

          {
            mode ?
              <table className="table som">
                <tr>
                  <th style={{ width: '100%' }}>Action</th>
                  <th>Allow</th>
                </tr>
                {(modeActions).map((action, i) => (
                  <tr key={i}>
                    <td>{action.show}</td>
                    <td>
                      <Checkbox
                        contents={''}
                        value={checkedActions.includes(action.id)}
                        onChange={() => handleChangeModeActions(action.id)}
                      />
                    </td>
                  </tr>
                ))}
              </table> :
              <table className="table som">
                <tr>
                  <th style={{ width: '100%' }}>Action</th>
                  <th>Allow</th>
                  <th></th>
                </tr>
                {(newPermissionData.actions as ActionProp[]).map((action, i) => (
                  <tr key={i}>
                    <td>{action.show}</td>
                    <td>
                      <Checkbox contents={''} value={true} />
                    </td>
                    <td>
                      <button className="_zeroed _iconed _red" onClick={() => removeAction(i)}>
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
          }

          <div
            className="flex-container wrap"
            style={{ justifyContent: 'flex-end', marginTop: '20px' }}
          >
            <div
              className="legend"
              style={{ fontSize: '16px', opacity: '.6', fontWeight: 'normal' }}
            >
              Copy actions from another account permissions
            </div>

            <div className="legend-action-wrapper">
              <div
                className="input-wrapper"
              >
                <Select
                  options={permissionsRows.map((action) => ({
                    span: action.name,
                    value: action.account_permission_id
                  }))}
                  selectedOption={selectedPermissionRow}
                  onChange={(value) => setSelectedPermissionRow(value as string)}
                />
                <span
                  style={{
                    cursor: !isSaving && selectedPermissionRow ? 'pointer' : 'inherit',
                    opacity: !isSaving && selectedPermissionRow ? '1' : '.6'
                  }}
                  onClick={() => !isSaving && selectedPermissionRow && loadingPermissions()}
                >
                  <Icon
                    viewBox="0 0 16 16"
                    style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                    icon="download-1"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => $router.router.navigate('accounts_permissions', {
                companyId: activeAccountId,
              }, {
                reload: true
              })}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSave || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default AccountPermissionsPage_New
