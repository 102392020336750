import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Switcher from "../../../components/Switcher"
import SettingsSchedule from '../SettingsSchedule'
import Checkbox from "../../../components/Checkbox";
import SelectComponent from "../../../components/Select";
import AudioPlayer from "../../../components/AudioPlayer";

import { dateToInfoBlock, getBase64FromRington, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import { UserGroupsProps } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios"

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: SettingsData
}

interface SettingsData {
  permissions: {
    call_center_add: boolean
    call_center_delete: boolean
    call_center_edit: boolean
    call_center_report_show: boolean
    call_center_show: boolean

    call_center_schedule_add: boolean
    call_center_schedule_delete: boolean
    call_center_schedule_edit: boolean
    call_center_schedule_show: boolean
  },
  call_center: {
    app_sid: string
    call_time_out: number
    created_by: string
    created_at: string
    is_call_center: boolean
    twilio_account_sid: string
    twilio_call_token: string
    twilio_token: string
    updated_by: string
    updated_at: string
    voice: string
    hold_audio_file: string | null
    hold_audio_url: string | null
    user_groups: {
      name: string
      user_group_id: string
    }[]
  }

  edit: {
    user_groups: {
      name: string
      user_group_id: string
    }[]
  }
}

const emptyUserGroup = {
  name: '',
  user_group_id: '',
}

function CallCenter_SettingsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [page, setPage] = useState('main')

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)

  const [userGroupsData, setUserGroupsData] = useState<UserGroupsProps[]>([])

  const [availableUserGroups, setAvailableUserGroups] = useState<UserGroupsProps[]>([])
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupsProps>(emptyUserGroup)

  const [settingsData, setSettingsData] = useState<SettingsData | null>(null)

  const [audioFile, setAudioFile] = useState<string | null>(null)
  const [isChangeFile, setIsChangeFile] = useState(false)

  const [newSettingsData, setNewSettingsData] = useState<{ [key: string]: any }>({

  })

  const [inputTypes, setInputTypes] = useState({
    twilio_account_sid: 'password' as 'text' | 'password',
    twilio_call_token: 'password' as 'text' | 'password',
    twilio_token: 'password' as 'text' | 'password',
    twilio_app_sid: 'password' as 'text' | 'password',
  })

  async function handleSave() {
    setIsSaving(true)

    let requestData: {
      account_id: string | null
      is_call_center: number
      name: string
      twilio_account_sid: string
      app_sid: string
      twilio_call_token: string
      twilio_token: string
      call_time_out: string
      voice: string
      user_groups: string[]
      hold_audio_file?: string | null
      hold_audio_url?: null
    } = {
      account_id: activeAccountId,
      is_call_center: newSettingsData.is_call_center ? 1 : 0,
      name: newSettingsData.name,
      twilio_account_sid: newSettingsData.twilio_account_sid,
      app_sid: newSettingsData.app_sid,
      twilio_call_token: newSettingsData.twilio_call_token,
      twilio_token: newSettingsData.twilio_token,
      call_time_out: newSettingsData.call_time_out,
      voice: newSettingsData.voice,
      user_groups: userGroupsData.map(item => item.user_group_id),
    }

    if (isChangeFile) {
      if (!!audioFile) {
        requestData.hold_audio_file = audioFile
      } else if (!newSettingsData.hold_audio_file) {
        requestData.hold_audio_url = null
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/call-center/888881006kbplm8ytz
      const response = await httpClientUpdate.put(`/call-center/${$router.router.getState().params.callCenterId}`, requestData)

      if (response.data.success) {
        setIsSaving(false)
        setAudioFile(null)
        loadInfo()
        setEditing(false)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/call-center/888881006kbplm8ytz?account_id=88888
      const { data: { data: settingsData, success, error } } = (await httpClientUpdate.get('/call-center/' + $router.router.getState().params.callCenterId, {
        params: {
          account_id: activeAccountId,
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setSettingsData(settingsData)
        setNewSettingsData({
          ...newSettingsData,
          ...settingsData.call_center,
          hold_audio_file: settingsData.call_center.hold_audio_file ? settingsData.call_center.hold_audio_file : ''
        })

        let _availableUserGroups = []

        _availableUserGroups = settingsData.edit.user_groups.filter(($userGroup) => {

          return settingsData.call_center.user_groups.findIndex((userGroup) => {
            return userGroup.user_group_id === $userGroup.user_group_id
          }) === -1
        })

        setUserGroupsData(settingsData.call_center.user_groups)

        setAvailableUserGroups(_availableUserGroups)

        setSelectedUserGroup(emptyUserGroup)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/call-center/888881006kbplm8ytz?account_id=88888
      const response = await httpClientUpdate.delete(`/call-center/${$router.router.getState().params.callCenterId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.callCenters', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load setting data
  useEffect(() => {
    page === 'main' && loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  // Watch changes
  useEffect(() => {
    setReadyToSave(true)
  }, [
    newSettingsData, userGroupsData
  ])

  function handleCancel() {
    settingsData && setNewSettingsData({
      ...settingsData.call_center
    })
    setAudioFile(null)
    setReadyToSave(false)
    setEditing(false)
  }

  function handleBackSettings() {
    setPage('main')
  }

  function addUserGroup() {

    if (!userGroupsData || !newSettingsData) return

    const newUserGroupsList = [
      ...userGroupsData,
      selectedUserGroup
    ]

    setUserGroupsData(newUserGroupsList as any)

    let _availableUserGroups = [...availableUserGroups]

    _availableUserGroups = _availableUserGroups.filter(($userGroup) => {

      return newUserGroupsList.findIndex((userGroup) => {
        return userGroup.name === $userGroup.name
      }) === -1
    })

    setAvailableUserGroups([
      ..._availableUserGroups
    ])

    setSelectedUserGroup(emptyUserGroup)
  }

  function removeCallGroup(i: number) {

    let _callFlowUserGroups = [...userGroupsData]

    let _userGroup = _callFlowUserGroups[i]

    _callFlowUserGroups.splice(i, 1)

    setUserGroupsData(_callFlowUserGroups)

    setAvailableUserGroups([
      ...availableUserGroups,
      _userGroup
    ])
  }

  function addFile(event: any) {
    if (event.target.files[0]) {
      setReadyToSave(true)
      setIsChangeFile(true)

      getBase64FromRington(event.target.files[0])
        .then(result => {
          let file = result;
          setAudioFile(file as string)
        })
        .catch(err => { });
    }
  }

  // Render function
  return (<>
    {
      page === 'main' ?
        <>
          {newSettingsData ? (
            <div className="CallCenter_SettingsPage_Item entity-edit">

              {/* Top navigation */}
              <div className="top-nav">
                <BaseLink
                  router={$router.router}
                  routeName="call_center.phoneNumbers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
                >
                  <Icon icon="phone-1" />
                  <span>Phone Numbers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.dispatchers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
                >
                  <Icon icon="user-1" />
                  <span>Dispatchers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.additionalTime"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="time-22" />
                  <span>Additional Time</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.absence"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.absence" })}
                >
                  <Icon icon="time-16" />
                  <span>Absence</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callFlows"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
                >
                  <Icon icon="share-7" />
                  <span>Call Flows</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.caller_groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Caller Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.temporaryClosed"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="close-664" />
                  <span>Temporary Closed</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callCenters"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
                >
                  <Icon icon="delivery-8" />
                  <span>Call Centers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.audioLibrary"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="audio-15" />
                  <span>Audio Library</span>
                </BaseLink>
              </div>

              <div className="wrapper flex-container sb">
                <h1>Call Center</h1>

                <div style={{ display: 'flex' }}>
                  <button
                    style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
                    className="_wa"
                    onClick={() => $router.router.navigate('call_center.callCenters', {
                      companyId: activeAccountId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  {
                    <button
                      className="_wa"
                      style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
                      disabled={editing}
                      onClick={() => setPage('schedule')}
                    >
                      Schedule
                    </button>
                  }

                  {
                    !editing && settingsData?.permissions.call_center_edit &&
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      Edit
                    </button>
                  }
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__right">
                    <div className="field">
                      <span>Name:</span>

                      <input
                        disabled={!editing}
                        type="text"
                        value={newSettingsData.name}
                        onChange={(event) => setNewSettingsData({ ...newSettingsData, name: event.target.value })}
                      />
                    </div>
                  </div>

                  <div className="__left">
                    <div className="field">
                      <span>Active:</span>

                      <Switcher
                        disabled={!editing}
                        checked={newSettingsData.is_call_center}
                        onChange={(value) => setNewSettingsData({ ...newSettingsData, is_call_center: value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="flex-container sb wrap">
                  <div className="legend">User Access</div>

                  <div className="legend-action-wrapper">
                    <label>User Group:</label>

                    <div className="input-wrapper">
                      <SelectComponent
                        disabled={!editing}
                        options={availableUserGroups.map((userGroup) => ({
                          span: userGroup.name as string,
                          value: userGroup.name as string
                        }))}
                        selectedOption={selectedUserGroup.name as string}
                        onChange={(value) => setSelectedUserGroup(availableUserGroups.find((_userGroup) => _userGroup.name === value) as UserGroupsProps)} />

                      <button
                        disabled={!selectedUserGroup.name}
                        className="_green"
                        onClick={() => addUserGroup()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <table className='table'>
                  <tr>
                    <th>User Group</th>
                    {
                      editing &&
                      <th></th>
                    }
                  </tr>

                  {(userGroupsData as UserGroupsProps[]).map((userGroup, i) => (
                    <tr key={userGroup.user_group_id}>
                      <td>{userGroup.name}</td>
                      {
                        editing &&
                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            disabled={!editing}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeCallGroup(i)
                            }}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      }
                    </tr>
                  ))}
                </table>
              </div>

              {
                editing ?
                  <div className="fieldset">
                    <div className="legend">Twilio</div>

                    <div className="fields">
                      <div className="__left">
                        <div className="field">
                          <span>Account SID:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_account_sid}
                              // readOnly={inputTypes.twilio_account_sid !== 'text'}
                              defaultValue={newSettingsData.twilio_account_sid}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_account_sid: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_account_sid: inputTypes.twilio_account_sid === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_account_sid === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>App SID:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_app_sid}
                              // readOnly={inputTypes.twilio_app_sid !== 'text'}
                              value={newSettingsData.app_sid}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, app_sid: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_app_sid: inputTypes.twilio_app_sid === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_app_sid === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Voice:</span>

                          <input
                            type="text"
                            disabled={!editing}
                            defaultValue={newSettingsData.voice}
                            onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, voice: value })}
                          />
                        </div>

                        <div className="field">
                          <span>Hold Audio:</span>

                          {
                            newSettingsData.hold_audio_url ?
                              <AudioPlayer audioSrc={newSettingsData.hold_audio_url} /> :
                              <input
                                type="file"
                                style={{ display: 'block' }}
                                onChange={addFile}
                              />
                          }

                          {
                            (!!audioFile || newSettingsData.hold_audio_url) &&
                            <div
                              style={{ width: '16px', cursor: 'pointer', marginLeft: '20px' }}
                              onClick={() => {
                                setAudioFile(null)
                                setReadyToSave(true)
                                setNewSettingsData({
                                  ...newSettingsData,
                                  hold_audio_file: null,
                                  hold_audio_url: null,
                                })
                                setIsChangeFile(true)
                              }}
                            >
                              <Icon icon="x-mark-1" />
                            </div>
                          }
                        </div>
                      </div>

                      <div className="__right">
                        <div className="field">
                          <span>Token:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_token}
                              // readOnly={inputTypes.twilio_token !== 'text'}
                              defaultValue={newSettingsData.twilio_token}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_token: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_token: inputTypes.twilio_token === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_token === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Call Token:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_call_token}
                              // readOnly={inputTypes.twilio_call_token !== 'text'}
                              defaultValue={newSettingsData.twilio_call_token}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_call_token: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_call_token: inputTypes.twilio_call_token === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_call_token === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Call Time Out:</span>

                          <input
                            type="number"
                            className="appearance"
                            disabled={!editing}
                            defaultValue={newSettingsData.call_time_out || ''}
                            onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, call_time_out: +value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="fieldset">
                    <div className="legend">Twilio</div>

                    <div className="fields">
                      <div className="__left">
                        <div className="field">
                          <span>Account SID:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_account_sid}
                              // readOnly={inputTypes.twilio_account_sid !== 'text'}
                              defaultValue={newSettingsData.twilio_account_sid}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_account_sid: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_account_sid: inputTypes.twilio_account_sid === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_account_sid === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>App SID:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_app_sid}
                              // readOnly={inputTypes.twilio_app_sid !== 'text'}
                              defaultValue={newSettingsData.app_sid}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, app_sid: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_app_sid: inputTypes.twilio_app_sid === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_app_sid === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Voice:</span>
                          <input
                            type="text"
                            disabled={!editing}
                            defaultValue={newSettingsData.voice}
                            onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, voice: value })}
                          />
                        </div>

                        <div className="field">
                          <span>Hold Audio:</span>

                          {
                            newSettingsData.hold_audio_url &&
                            <AudioPlayer audioSrc={newSettingsData.hold_audio_url} />
                          }
                        </div>
                      </div>

                      <div className="__right">
                        <div className="field">
                          <span>Token:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_token}
                              // readOnly={inputTypes.twilio_token !== 'text'}
                              defaultValue={newSettingsData.twilio_token}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_token: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_token: inputTypes.twilio_token === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_token === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Call Token:</span>
                          <div className="form-field _iconed">
                            <input
                              disabled={!editing}
                              type={inputTypes.twilio_call_token}
                              // readOnly={inputTypes.twilio_call_token !== 'text'}
                              defaultValue={newSettingsData.twilio_call_token}
                              onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, twilio_call_token: value })}
                            />
                            <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_call_token: inputTypes.twilio_call_token === 'text' ? 'password' : 'text' })}>
                              {
                                inputTypes.twilio_call_token === 'text' ?
                                  <Icon icon="eye-4" /> :
                                  <Icon
                                    style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="eye-block"
                                  />
                              }
                            </button>
                          </div>
                        </div>

                        <div className="field">
                          <span>Call Time Out:</span>

                          <input
                            type="number"
                            className="appearance"
                            disabled={!editing}
                            defaultValue={newSettingsData.call_time_out || ''}
                            onChange={({ target: { value } }) => setNewSettingsData({ ...newSettingsData, call_time_out: +value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              }

              <div className="fieldset">
                <div className="legend">Info</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date Created:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newSettingsData.created_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Created by:</span>
                      <input
                        type="text"
                        defaultValue={newSettingsData.created_by}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Last Edited:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newSettingsData.updated_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Last Edited by:</span>
                      <input
                        type="text"
                        defaultValue={newSettingsData.updated_by}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {
                editing &&
                <div className="wrapper flex-container sb editing-buttons">
                  <div>
                    {
                      settingsData?.permissions.call_center_delete &&
                      <button
                        className="_red"
                        onClick={() => setDeleting(true)}>
                        Delete Call Center
                      </button>
                    }
                  </div>

                  <div className="buttons">
                    <button className="_bordered _red"
                      onClick={() => handleCancel()}>
                      Cancel
                    </button>
                    <button
                      className="_bordered _green"
                      disabled={!readyToSave || isSaving}
                      onClick={() => handleSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              }

              {/* Item share popup */}
              {settingsData?.permissions.call_center_delete && deleting ? (
                <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                  <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                    <div className="title">
                      Delete Call Center
                    </div>

                    <div className="checkbox-wrapper">
                      <Checkbox contents="I understand that after deleting the call center it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                    </div>

                    <div className="buttons">

                      <button
                        className="_bordered _green"
                        onClick={() => setDeleting(false)}
                      >
                        Cancel
                      </button>

                      <button
                        className="_bordered _red"
                        disabled={!readyToDelete || isSaving}
                        onClick={() => handleRemoveItem()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </> :
        <SettingsSchedule
          handleBackSettings={handleBackSettings}
          permissions={
            {
              call_center_schedule_add: settingsData ? settingsData.permissions.call_center_schedule_add : false,
              call_center_schedule_delete: settingsData ? settingsData.permissions.call_center_schedule_delete : false,
              call_center_schedule_edit: settingsData ? settingsData.permissions.call_center_schedule_edit : false,
              call_center_schedule_show: settingsData ? settingsData.permissions.call_center_schedule_show : false,

              call_center_settings_edit: true,
              call_center_settings_show: true,

              twilio_info_edit: true,
              twilio_info_show: true,
            }
          }
        />
    }
  </>)
}

export default CallCenter_SettingsPage_Item