import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import { CallGroupsReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallGroupsReport
}

interface UsersProps {
  available: string;
  first_name: string;
  function: string;
  last_name: string;
  user_id: string;
}

const emptyUser: UsersProps = {
  available: '',
  first_name: '',
  function: '',
  last_name: '',
  user_id: '',
}

function UserGroupsPage_New() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<UsersProps[] | null>(null)
  const [availableUsers, setAvailableUsers] = useState<UsersProps[]>([])
  const [selectedUser, setSelectedUser] = useState<UsersProps>(emptyUser)
  const [newUserGroupsData, setNewUserGroupsData] = useState({
    name: '',
    users: [] as UsersProps[],
  })

  // update document
  async function handleSave(): Promise<void> {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/users/groups
      const response = await httpClientUpdate.post(`/users/groups`, {
        account_id: activeAccountId,
        name: newUserGroupsData.name,
        users: newUserGroupsData.users.map(item => item.user_id)
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('user_groups', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/users/groups/report?account_id=88888&limit_rows=10[…]=2&sort_field=users.user_id&sort_type=desc&search=test
      const { data: { data: callGroupsReport, success } } = (await httpClientUpdate.get('/users/groups/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
          sort_field: 'name',
          sort_type: 'asc',
          filter_field: JSON.stringify({})
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        let _availableUsers = []

        _availableUsers = callGroupsReport.edit.users.filter((item) => item)

        setUsers(callGroupsReport.edit.users)
        setAvailableUsers([emptyUser].concat(_availableUsers))
        setSelectedUser(emptyUser)

        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`403`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load callGroup data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Add user function
  function addUser() {

    if (!newUserGroupsData.users || !newUserGroupsData) return

    const newUsersList = [
      ...newUserGroupsData.users,
      selectedUser
    ]

    setNewUserGroupsData({
      ...newUserGroupsData,
      users: newUsersList
    })

    let _availableUser = [...availableUsers]

    _availableUser = _availableUser.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user.user_id === $user.user_id
      }) === -1
    })

    setAvailableUsers([
      ..._availableUser
    ])

    setSelectedUser(emptyUser)

    setReadyToSave(true)
  }

  // Remove dispatcher function
  function removeUser(i: number) {

    let _callerGroupUsers = [...newUserGroupsData.users]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setNewUserGroupsData({
      ...newUserGroupsData,
      users: _callerGroupUsers
    })

    setAvailableUsers([
      ...availableUsers,
      _user
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newUserGroupsData
  ])

  // Render function
  return (<>
    {true ? (
      <div className="CallCenter_CallGroupsPage_New entity-edit">
        <div className="wrapper flex-container sb">
          <h1>New User Groups</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('user_groups', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewUserGroupsData({ ...newUserGroupsData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Users</div>

            <div className="legend-action-wrapper">
              <div className="input-wrapper">
                <Select
                  options={availableUsers.map((user) => ({
                    span: `${user.first_name} ${user.last_name}`,
                    value: user.user_id
                  }))}
                  selectedOption={selectedUser.user_id}
                  onChange={(value) => setSelectedUser(availableUsers.find((user) => user.user_id === value) as UsersProps)}
                />
                <button
                  disabled={!selectedUser.user_id}
                  className="_green"
                  onClick={() => addUser()}
                >
                  Add
                </button>
              </div>
            </div>

          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>Function</th>
              <th>Active</th>
              <th></th>
            </tr>
            {newUserGroupsData.users.map((user, i) => (
              <tr
                key={i}
              >
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.function}</td>
                <td className={classNames({
                  'red-text': user.available === 'Inactive',
                  'green-text': user.available === 'Active',
                  'blue-text': user.available === 'Waiting',
                })}>
                  {user.available}
                </td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    onClick={() => removeUser(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {newUserGroupsData.users.map((user, i: number) => (
                <div
                  className="item"
                  key={i}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>Name<b>{user.first_name} {user.last_name}</b></div>
                    </div>

                    <div className="__right">
                      <div>Function<b>{user.function}</b></div>
                      <div className={classNames({
                        'red-text': user.available === 'Inactive',
                        'green-text': user.available === 'Active',
                        'blue-text': user.available === 'Waiting',
                      })}>
                        {user.available}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__right">
                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => removeUser(i)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('user_groups', {
              companyId: activeAccountId
            }, {
              reload: true
            })}>
              Cancel
            </button>
            <button
              disabled={!readyToSave || !!errorFields.length || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default UserGroupsPage_New
