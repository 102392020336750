import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment from "moment"
import { DateTime } from "luxon"
import DateTimeComponent from "react-datetime"

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import { AdditionalTimeReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AdditionalTimeReport
}

function AbsencesPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newData, setNewData] = useState({
    service_resource_id: '',
    additional_time_start: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
    additional_time_end: DateTime.now().plus({ hours: 1 }).set({ minute: 0 }).toJSDate(),
  })

  const [serviceResources, setServiceResources] = useState<{
    code: number
    nickname: string
    service_resource_id: string
    time_zone: string
  }[] | null>(null)

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      let start = moment(newData.additional_time_start).format('MM/DD/YYYY hh:mma')
      let end = moment(newData.additional_time_end).format('MM/DD/YYYY hh:mma')
      let timeZone = serviceResources?.filter(item => item.service_resource_id === newData.service_resource_id)

      // https://2022back4.artemiudintsev.com/api/additional-times
      const response = await httpClientUpdate.post(`/additional-times`, {
        account_id: activeAccountId,
        service_resource_id: newData.service_resource_id,
        additional_time_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone && timeZone[0].time_zone, start),
        additional_time_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone && timeZone[0].time_zone, end),
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('additional-time', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      //  https://2022back4.artemiudintsev.com/api/additional-times/report?account_id=88888&page=1&limit_rows=100
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/additional-times/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
          date_type: 'created',
          sort_field: 'created_at',
          sort_type: 'asc',
          filter_field: JSON.stringify({})
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (responseData.permissions.additional_time_service_resource_add) {
          setServiceResources(responseData.edit.service_resources)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  // Render function
  return (<>
    {serviceResources ? (
      <div className="AbsencesPage_Item AbsencePage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Additional Time</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('additional-time', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Service resource:</span>

                <Select
                  options={serviceResources.map((option) => ({
                    span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                    value: option.service_resource_id
                  }))}
                  selectedOption={newData.service_resource_id as string}
                  onChange={(value) => setNewData({ ...newData, service_resource_id: value as string })}
                />
              </div>
            </div>


            <div className="__right">

              <div className="field">
                <span>Start:</span>

                <DateTimeComponent
                  value={newData.additional_time_start as Date}
                  onChange={(value) => setNewData({ ...newData, additional_time_start: moment.isMoment(value) ? value.toDate() : newData.additional_time_start })}
                />
              </div>

              <div className="field">
                <span>End:</span>

                <DateTimeComponent
                  value={newData.additional_time_end as Date}
                  onChange={(value) => setNewData({ ...newData, additional_time_end: moment.isMoment(value) ? value.toDate() : newData.additional_time_end })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div></div>
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('additional-time', {
              companyId: activeAccountId
            }, { reload: true })}>
              Cancel
            </button>

            <button
              disabled={!readyToSave || newData.service_resource_id === '' || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_New
