import { useEffect, useState, useCallback, useRef } from "react";
import { useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import ReactCrop, { Crop } from "react-image-crop"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import UserCustom from "../../models/UserCustom"
import Switcher from "../../components/Switcher"

import { dateToInfoBlock, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

// import User from "../../models/User"
interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UserData
}

interface UserData {
  permissions: {
    user_add: boolean
    user_delete: boolean
    user_edit: boolean
    user_report_show: boolean
    user_show: boolean
  },
  user: UserCustom[],
  edit: {
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      name: string
      users_permissions_id: string
    }[],
    state: {
      id: number
      name: string
    }[],
    users_menus: {
      name: string
      users_menus_id: string
    }[]
  }
}

interface ActionPermissionsProps {
  name: string
  users_permissions_id: string
}

interface ActionMenusProps {
  name: string
  users_menus_id: string
}


function generateDownload(canvas: {
  toDataURL(arg0: string, arg1: number): any; toBlob: (arg0: (blob: Blob | MediaSource) => void, arg1: string, arg2: number) => void;
}, crop: any) {
  try {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob: Blob | MediaSource) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
      },
      'image/png',
      1
    );
    return canvas.toDataURL("image/jpeg", 0.95)

  } catch (err) {

  }
}

function setCanvasImage(image: { naturalWidth: number; width: number; naturalHeight: number; height: number; }, canvas: { getContext: (arg0: string) => any; width: number; height: number; }, crop: { width: number; height: number; x: number; y: number; }) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}

async function imageUrlToBase64(url: string) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
}

function UsersPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false)

  const [upImg, setUpImg] = useState<string>('');

  const [crop, setCrop] = useState<Partial<Crop>>({ unit: '%', width: 100, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);


  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [userData, setUserData] = useState<UserData | null>(null)

  const [avatarPopup, setAvatarPopup] = useState(false)

  const [editions, setEditions] = useState<{
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      name: string
      users_permissions_id: string
    }[],
    state: {
      id: number
      name: string
    }[],
    users_menus: {
      name: string
      users_menus_id: string
    }[]
  } | null>(null)

  const [newUserData, setNewUserData] = useState<Partial<UserCustom>>({

  })
  const [draging, setDraging] = useState(false)
  const [updatedImage, setUpdatedImage] = useState(false)

  const [availablePermissions, setAvailablePermissions] = useState<ActionPermissionsProps[] | []>([])
  const [availableMenus, setAvailableMenus] = useState<ActionMenusProps[] | []>([])

  const [newPermissions, setNewPermissions] = useState<ActionPermissionsProps[] | []>([])
  const [newMenus, setNewMenus] = useState<ActionMenusProps[] | []>([])

  const [selectedPermissions, setSelectedPermissions] = useState('')
  const [selectedMenus, setSelectedMenus] = useState('')

  const [password, setPassword] = useState('')
  const [inputTypes, setInputTypes] = useState({
    password: 'password' as 'text' | 'password',
  })

  async function handleCancelEdit() {
    if (userData) {
      let _availablePermissions = userData.edit.permissions.filter(permission => !userData.user[0].user_permissions.includes(permission.users_permissions_id))
      let _availableMenus = userData.edit.users_menus.filter(menus => !userData.user[0].user_menus.includes(menus.users_menus_id))

      let permissions = userData.edit.permissions.filter(permission => userData.user[0].user_permissions.includes(permission.users_permissions_id))
      let menus = userData.edit.users_menus.filter(menus => userData.user[0].user_menus.includes(menus.users_menus_id))

      if (userData.user[0].photo_link) {
        const imageBase64 = await imageUrlToBase64(userData.user[0].photo_link)

        setUpImg(imageBase64 as string)
      }

      setAvailablePermissions(_availablePermissions)
      setAvailableMenus(_availableMenus)
      setSelectedPermissions('')
      setSelectedMenus('')
      setNewPermissions(permissions)
      setNewMenus(menus)
      setCrop({ unit: '%', width: 100, aspect: 1 })

      userData && setNewUserData({
        ...userData.user[0]
      })
      setEditing(false)
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/users/${$router.router.getState().params.userId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('users', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const reqData: {
        account_id: string | null,
        email: string | undefined,
        first_name: string | undefined,
        last_name: string | undefined,
        position: string | undefined,
        time_zone_id: string | null,
        available: string | undefined,
        function: string | undefined,
        phone: string | undefined,
        user_permissions: string[],
        user_menus: string[],
        address_street: string | undefined,
        address_state_id: string | null,
        address_unit: string | undefined,
        address_zip: string | undefined,
        address_city: string | undefined,
        password?: string,
        photo_link?: string,
      } = {
        account_id: activeAccountId,
        email: newUserData.email,
        // password: password,
        first_name: newUserData.first_name,
        last_name: newUserData.last_name,
        position: newUserData.function,
        time_zone_id: newUserData.time_zone_id ? `${newUserData.time_zone_id}` : null,
        available: newUserData.active_status,
        function: newUserData.function,
        phone: newUserData.phone && formatPhoneNumberToServerString(newUserData.phone),
        user_permissions: newPermissions.map(item => item.users_permissions_id),
        user_menus: newMenus.map(item => item.users_menus_id),
        // photo_link: previewCanvasRef.current && updatedImage ?  : newUserData.photo_link,
        address_street: newUserData.street,
        address_state_id: newUserData.state_id ? typeof newUserData.state_id === 'string' ? newUserData.state_id : `${newUserData.state_id}` : null,
        address_unit: newUserData.unit,
        address_zip: newUserData.zip,
        address_city: newUserData.city,
      }

      if (password) {
        reqData.password = password
      }

      if (updatedImage) {
        if (upImg) {
          reqData.photo_link = previewCanvasRef.current ? generateDownload(previewCanvasRef.current, crop) : newUserData.photo_link
        } else {
          reqData.photo_link = ''
        }
      } else {
        reqData.photo_link = newUserData.photo_link
      }

      // if (previewCanvasRef.current && updatedImage) {
      //   reqData.photo_link = generateDownload(previewCanvasRef.current, crop)
      // }

      const response = await httpClientUpdate.put(`/users/${$router.router.getState().params.userId}`, reqData)
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: userData, success, error } } = await httpClientUpdate.get('/users/' + $router.router.getState().params.userId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (userData.permissions.user_show) {
          let _availablePermissions = userData.edit.permissions.filter(permission => !userData.user[0].user_permissions.includes(permission.users_permissions_id))
          let _availableMenus = userData.edit.users_menus.filter(menus => !userData.user[0].user_menus.includes(menus.users_menus_id))

          let permissions = userData.edit.permissions.filter(permission => userData.user[0].user_permissions.includes(permission.users_permissions_id))
          let menus = userData.edit.users_menus.filter(menus => userData.user[0].user_menus.includes(menus.users_menus_id))

          setAvailablePermissions(_availablePermissions)
          setAvailableMenus(_availableMenus)
          setSelectedPermissions('')
          setSelectedMenus('')
          setNewPermissions(permissions)
          setNewMenus(menus)

          setUserData(userData)
          setEditions(userData.edit)
          setNewUserData({
            ...userData.user[0]
          })
          setPassword(userData.user[0].password)

          if (userData.user[0].photo_link) {
            const imageBase64 = await imageUrlToBase64(userData.user[0].photo_link)

            setUpImg(imageBase64 as string)
          }

        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load user data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add permissions function
  function addAction() {
    if (!userData || !newUserData || !newUserData.user_permissions) return

    let updatePermissions = newPermissions.map(item => item)
    userData?.edit.permissions.forEach(item => item.users_permissions_id === selectedPermissions && updatePermissions.push(item))

    setNewPermissions(updatePermissions)

    let _availablePermissions = userData.edit.permissions.filter(permission => !updatePermissions.map(item => item.users_permissions_id).includes(permission.users_permissions_id))
    setAvailablePermissions(_availablePermissions)

    setSelectedPermissions('')
    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _permissionActions = newPermissions.map(item => item)

    let _action = _permissionActions[i]

    _permissionActions.splice(i, 1)

    setNewPermissions(_permissionActions)

    setAvailablePermissions([
      ...availablePermissions,
      _action
    ])

    setReadyToSave(true)
  }

  // Add menus function
  function addMenusAction() {
    if (!userData || !newUserData || !newUserData.user_menus) return

    let updateMenus = newMenus.map(item => item)
    userData?.edit.users_menus.forEach(item => item.users_menus_id === selectedMenus && updateMenus.push(item))

    setNewMenus(updateMenus)

    let _availableMenus = userData.edit.users_menus.filter(menus => !updateMenus.map(item => item.users_menus_id).includes(menus.users_menus_id))
    setAvailableMenus(_availableMenus)

    setSelectedMenus('')
    setReadyToSave(true)
  }

  // Remove action function
  function removeMenusAction(i: number) {

    let _menusActions = newMenus.map(item => item)

    let _action = _menusActions[i]

    _menusActions.splice(i, 1)

    setNewMenus(_menusActions)

    setAvailableMenus([
      ...availableMenus,
      _action
    ])

    setReadyToSave(true)
  }

  // On select file function
  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onDragOver = useCallback((e) => {
    setDraging(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  useEffect(() => setReadyToSave(true), [
    newUserData, updatedImage, password
  ])

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (imgRef.current &&
      previewCanvasRef.current &&
      completedCrop) {

      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }

  }, [completedCrop]);

  async function handleCancelUploadImage() {
    if (userData && userData.user[0].photo_link) {
      const imageBase64 = await imageUrlToBase64(userData.user[0].photo_link)

      setUpImg(imageBase64 as string)
    }

    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(false)
    setAvatarPopup(false)
  }

  function handleDeleteImage() {
    setUpImg('')
    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(true)
    setAvatarPopup(false)
  }

  // Render function
  return (<>
    {userData && editions && newUserData?.time_zone ? (
      <div className="UsersPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <div className="flex-container _gap-narrow">
            <h1>User:</h1>
            <div className="item-name">
              {userData.user[0].first_name} {userData.user[0].last_name}
            </div>
          </div>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'users',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                userData?.permissions.user_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>First Name:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].first_name}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, first_name: value })}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].last_name}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, last_name: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Active:</span>
                <Switcher
                  disabled={!editing}
                  checked={newUserData.active_status === 'Active'}
                  onChange={(value) => setNewUserData({ ...newUserData, active_status: value ? 'Active' : 'Inactive' })}
                />
              </div>

              <div className="field">
                <span>Email:</span>
                <input
                  type="text"
                  defaultValue={userData.user[0].email}
                  disabled={true}
                />
              </div>
            </div>

            <div className="avatar-editing">
              <button
                className="_zeroed _iconed"
                onClick={() => {
                  editing && setAvatarPopup(true)
                  editing && setUpdatedImage(true)
                }}
              >
                {
                  updatedImage ?
                    upImg ?
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: '90px',
                          height: '90px',
                        }}
                      /> :
                      <Icon icon="user-20" />
                    :
                    newUserData.photo_link ?
                      <img src={newUserData.photo_link} alt="User avatar" /> :
                      <Icon icon="user-20" />
                }
              </button>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Settings</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Function:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].function}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, function: value })}
                />
              </div>

              <div className="field">
                <span>Phone:</span>
                <ReactInputMask
                  type="text"
                  disabled={!editing}
                  mask="+1 (999) 999-9999"
                  value={newUserData.phone}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, phone: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field" style={{ zIndex: 7 }}>
                <span>Time Zone:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', id: 0 }].concat(editions.time_zone).map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={newUserData.time_zone_id as number}
                  onChange={(value: number) => setNewUserData({ ...newUserData, time_zone_id: value })}
                />
              </div>

              {
                editing &&
                <div className="field">
                  <span>Password:</span>
                  <div className="form-field _iconed">
                    <input
                      disabled={!editing}
                      autoComplete='new-password'
                      type={inputTypes.password}
                      defaultValue={password || ''}
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                    <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, password: inputTypes.password === 'text' ? 'password' : 'text' })}>
                      {
                        inputTypes.password === 'text' ?
                          <Icon icon="eye-4" /> :
                          <Icon
                            style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="eye-block"
                          />
                      }
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Permissions</div>

            <div className="legend-action-wrapper">
              <label>Permissions:</label>
              <div style={{ zIndex: '7' }} className="input-wrapper">
                <Select
                  disabled={!editing}
                  options={availablePermissions.map((action) => ({
                    span: action.name,
                    value: action.users_permissions_id
                  }))} selectedOption={selectedPermissions}
                  onChange={(value) => setSelectedPermissions(value as string)}
                />

                <button
                  disabled={!selectedPermissions}
                  className="_green"
                  onClick={() => addAction()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className="table som">
            <tr>
              <th style={{ width: '100%' }}>Permissions</th>
              <th></th>
            </tr>
            {(newPermissions as ActionPermissionsProps[]).map((action, i) => (
              <tr
                key={i}
                style={{ cursor: editing ? 'pointer' : 'inherit' }}
              >
                <td>{action.name}</td>
                <td>
                  <button
                    disabled={!editing}
                    className="_zeroed _iconed _red"
                    onClick={() => removeAction(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Menus</div>

            <div className="legend-action-wrapper">
              <label>Menu:</label>
              <div className="input-wrapper">
                <Select
                  disabled={!editing}
                  options={availableMenus.map((action) => ({
                    span: action.name,
                    value: action.users_menus_id
                  }))} selectedOption={selectedMenus}
                  onChange={(value) => setSelectedMenus(value as string)}
                />

                <button
                  disabled={!selectedMenus}
                  className="_green"
                  onClick={() => addMenusAction()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className="table som">
            <tr>
              <th style={{ width: '100%' }}>Menu</th>
              <th></th>
            </tr>
            {(newMenus as ActionMenusProps[]).map((action, i) => (
              <tr
                key={i}
                style={{ cursor: editing ? 'pointer' : 'inherit' }}
              >
                <td>{action.name}</td>
                <td>
                  <button
                    disabled={!editing}
                    className="_zeroed _iconed _red"
                    onClick={() => removeMenusAction(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="fieldset">
          <div className="legend">Address</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Street:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].street}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, street: value })}
                />
              </div>

              <div className="field">
                <span>Unit/Apt:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].unit}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, unit: value })}
                />
              </div>

              <div className="field">
                <span>City:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].city}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, city: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>State:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={typeof newUserData.state_id === 'string' ? +newUserData.state_id : newUserData.state_id as string | number}
                  onChange={(value: number) => setNewUserData({ ...newUserData, state_id: value })}
                />
              </div>

              <div className="field">
                <span>Zip:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={userData.user[0].zip}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, zip: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, userData.user[0].created_at || '')}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" value={userData.user[0].created_by || ''} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, userData.user[0].updated_at || '')}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" value={userData.user[0].updated_by || ''} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                userData.permissions.user_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete User</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {avatarPopup ? (
          <div
            className="popup imageCrop"
            onClick={() => setAvatarPopup(false)}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={(event) => {
              draging && onDrop(event)
            }}
          >

            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                draging ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      name="myImage"
                      onChange={onSelectFile}
                      id="file-uploader"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader')?.click()}
                    >
                      <Icon icon="user-33" />
                      <span>Upload a File</span>
                    </button>
                  </div>
              }


              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                {
                  upImg &&
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                }
              </div>

              {
                upImg &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => handleCancelUploadImage()}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _red"
                    onClick={() => handleDeleteImage()}
                  >
                    Delete
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      setAvatarPopup(false)
                      setDraging(false)
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {userData.permissions.user_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete User
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the user it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default UsersPage_Item
