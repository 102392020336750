import React, { useState, useEffect } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import { httpClientUpdate, nErrorUpdate } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

import "../../styles/pages/lists.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: SpamFilters
}

interface SpamItem {
  name: string
  spam_filter_id: string
  editing: boolean
}
interface PermissionsProps {
  list_spam_filter_add: boolean
  list_spam_filter_delete: boolean
  list_spam_filter_edit: boolean
  list_spam_filter_show: boolean
}

interface SpamFilters {
  list_spam_filters: SpamItem[]

  permissions: PermissionsProps
}

function ListsPage_SpamFilters() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    name: ''
  })

  const [editItemForm, setEditItemForm] = useState({
    name: ''
  })

  const [items, setItems] = useState<SpamItem[] | []>([])
  const [permissions, setPermissions] = useState<PermissionsProps>({
    list_spam_filter_add: true,
    list_spam_filter_delete: true,
    list_spam_filter_edit: true,
    list_spam_filter_show: true,
  })
  // Load Spam Filters function
  async function loadSpamFilters() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      // https://2022back4.artemiudintsev.com/api/lists/spam-filters?account_id=88888
      const { data: { data: spamData, success, error } } = (await httpClientUpdate.get(`/lists/spam-filters`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setItems(spamData?.list_spam_filters.map(item => {
          return { ...item, editing: false }
        }))
        setPermissions(spamData.permissions)
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // edit function
  async function editSpamFilters(id: string, name: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/lists/spam-filters/${id}`, qs.stringify({
        account_id: activeAccountId,
        name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return true
      } else {
        setIsSaving(false)
        return false
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  // add function
  async function addSpamFilters(name: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/spam-filters
      const response = await httpClientUpdate.post(`/lists/spam-filters`, qs.stringify({
        account_id: activeAccountId,
        name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: ''
        })
        return loadSpamFilters()
      } else {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: ''
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  // remove function
  async function removeSpamFilters(id: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/spam-filters/88888048hvczyr51nt
      const response = await httpClientUpdate.delete(`/lists/spam-filters/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadSpamFilters()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadSpamFilters()

    // unmount page
    return () => {
      setItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    if (errorAdd && newItemForm.name) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.name])

  useEffect(() => {
    if (errorEdit && editItemForm.name) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.name])

  // Add item function
  function addItem() {

    let { name } = newItemForm

    addSpamFilters(name)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      name: flag ? _items[i].name : ''
    })
  }

  // Set item function
  async function setItem(i: number) {
    let _items = [...items]
    _items[i] = {
      name: editItemForm.name,
      editing: false,
      spam_filter_id: _items[i].spam_filter_id,
    }
    let update = await editSpamFilters(_items[i].spam_filter_id, _items[i].name)
    update && setItems(_items)
  }

  // Render function
  return (
    <div className="ListsPage_Appliances">

      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      {/* Contents */}
      <div className="wrapper">

        <div className="flex-container sb header">
          <h1>List of Spam Filters</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This name is already taken</span>
          }

          {
            permissions.list_spam_filter_add &&
            <form onSubmit={(e) => { e.preventDefault(); addItem() }}>
              <div className="label">Name:</div>

              <input
                type="text"
                value={newItemForm.name}
                onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, name: value })}
              />

              <button
                className="_wa _green"
                disabled={newItemForm.name.length < 1 || isSaving || errorAdd}
              >
                Add
              </button>
            </form>
          }
        </div>

        {/* Table */}
        {!!items.length &&
          permissions.list_spam_filter_show &&
          < table className="table">
            <thead>
              <tr>
                <th style={{ width: '100%' }}>Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_spam_filter_edit}
                        defaultValue={editItemForm.name}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, name: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => setItem(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_spam_filter_delete || isSaving}
                      onClick={() => removeSpamFilters(item.spam_filter_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissions.list_spam_filter_edit}
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_spam_filter_delete || isSaving}
                      onClick={() => removeSpamFilters(item.spam_filter_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>}
      </div>
    </div >
  )
}

export default ListsPage_SpamFilters
