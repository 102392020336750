import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment from "moment"
import classNames from "classnames";
import Datetime from 'react-datetime'

import Select from "../../components/Select"
import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, screenSizeWidth, updatedDateToReqServer } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import Absence from "../../models/Absence"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AbsenceData
}

interface AbsenceData {

  permissions: {
    absence_add: boolean
    absence_delete: boolean
    absence_edit: boolean
    absence_report_show: boolean
    absence_show: boolean
  },

  absence: Absence,

  edit: {
    nickname: string,
    area: string,
    service_resource_id: string,
    area_id: string
  }[]
}

interface HttpTimeSlotsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    schedule: ScheduleProps[]
  }
}

interface ScheduleProps {
  day: string
  from: string
  limit: number
  schedule_id: string
  to: string
}

function AbsencesPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const width = screenSizeWidth()

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)
  const [errorTime, setErrorTime] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [absenceData, setAbsenceData] = useState<AbsenceData | null>(null)

  const [newAbsenceData, setNewAbsenceData] = useState<Partial<Absence>>({
  })
  const [isLoadTimeSlots, setIsLoadTimeSlots] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string[]>([])
  const [timeSlots, setTimeSlots] = useState<ScheduleProps[]>([])

  function handleCancelEdit() {
    setEditing(false)
    setTimeSlots([])
    setSelectedTimeSlot([])
    setIsLoadTimeSlots(false)

    if (absenceData) {
      let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mm a', absenceData.absence.time_zone, absenceData.absence.absence_start as string)
      let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mm a', absenceData.absence.time_zone, absenceData.absence.absence_end as string)
      let date = moment.utc(absenceData.absence.date).format('MM/DD/YYYY')

      if (absenceData.absence.type === 'timeslot') {
        setSelectedTimeSlot(absenceData.absence.time_slots.map(item => item.schedule_id))
      }

      setNewAbsenceData({
        ...absenceData.absence,
        absence_start: dateStart,
        absence_end: dateEnd,
        date,
        type: absenceData.absence.type ? absenceData.absence.type === 'timeslot' ? 'Timeslot' : absenceData.absence.type : 'Range'
      })
    }

  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/absences/${$router.router.getState().params.absenceId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('absences', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      if (newAbsenceData.type === 'Range') {
        let start = moment.isMoment(newAbsenceData.absence_start) ? moment(newAbsenceData.absence_start).format('MM/DD/YYYY hh:mm A') : newAbsenceData.absence_start
        let end = moment.isMoment(newAbsenceData.absence_end) ? moment(newAbsenceData.absence_end).format('MM/DD/YYYY hh:mm A') : newAbsenceData.absence_end

        const response = await httpClientUpdate.put(`/absences/${$router.router.getState().params.absenceId}`, {
          account_id: activeAccountId,
          service_resource: newAbsenceData.service_resource_id,
          area: newAbsenceData.area_id,
          absence_start: updatedDateToReqServer('MM/dd/yyyy hh:mm a', newAbsenceData.time_zone, start as string),
          absence_end: updatedDateToReqServer('MM/dd/yyyy hh:mm a', newAbsenceData.time_zone, end as string),
          note: newAbsenceData.note,
        })

        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      } else {
        const response = await httpClientUpdate.put(`/absences/${$router.router.getState().params.absenceId}`, {
          account_id: activeAccountId,
          service_resource_id: newAbsenceData.service_resource_id,
          area: newAbsenceData.area_id,
          time_slot_ids: selectedTimeSlot,
          date: moment(newAbsenceData.date).format('YYYY-MM-DD'),
          type: 'timeslot',
          note: newAbsenceData.note,
        })

        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('absences', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: absenceData, success, error } } = await httpClientUpdate.get('/absences/' + $router.router.getState().params.absenceId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (absenceData.permissions.absence_show) {
          setAbsenceData(absenceData)

          let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mm a', absenceData.absence.time_zone, absenceData.absence.absence_start as string)
          let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mm a', absenceData.absence.time_zone, absenceData.absence.absence_end as string)
          let date = moment.utc(absenceData.absence.date).format('MM/DD/YYYY')

          setNewAbsenceData({
            ...absenceData.absence,
            absence_start: dateStart,
            absence_end: dateEnd,
            date,
            type: absenceData.absence.type ? absenceData.absence.type === 'timeslot' ? 'Timeslot' : absenceData.absence.type : 'Range'
          })

          if (absenceData.absence.type === 'timeslot') {
            setSelectedTimeSlot(absenceData.absence.time_slots.map(item => item.schedule_id))
          }
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function getTimeSlots() {
    try {
      const { data: { data: timeSlotsReport, success, error } } = (await httpClientUpdate.get('/serviceresources/schedule', {
        params: {
          account_id: activeAccountId,
          service_resource_id: newAbsenceData.service_resource_id,
          date: moment(newAbsenceData.date).format('MM/DD/YYYY')
        }
      })) as { data: HttpTimeSlotsReport }
      if (success) {
        setIsLoadTimeSlots(true)
        setTimeSlots(timeSlotsReport.schedule.sort((a, b) => a.from > b.from ? 1 : -1).map(item => {
          return {
            ...item,
            from: moment(item.from, 'hh:mm:ss').format('hh:mmA'),
            to: moment(item.to, 'hh:mm:ss').format('hh:mmA'),
          }
        }))
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleSelectedTimeSlot(id: string) {
    if (selectedTimeSlot.includes(id)) {
      setSelectedTimeSlot(selectedTimeSlot.filter(item => item !== id))
    } else {
      let updatedSelected = selectedTimeSlot.map(item => item)
      updatedSelected.push(id)
      setSelectedTimeSlot(updatedSelected)
    }
  }

  useEffect(() => {
    setIsLoadTimeSlots(false)
    setSelectedTimeSlot([])
  }, [newAbsenceData.type])

  useEffect(() => {
    if (newAbsenceData.type === 'Timeslot' && newAbsenceData.date && newAbsenceData.service_resource) {
      getTimeSlots()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAbsenceData.date, newAbsenceData.type, newAbsenceData.service_resource])

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newAbsenceData, selectedTimeSlot
  ])

  useEffect(() => {
    if (newAbsenceData.type === 'Timeslot' && editing) {
      setSelectedTimeSlot([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moment(newAbsenceData.date).format('YYYY-MM-DD')])

  // Handle service resource changing
  useEffect(() => {

    let sr = newAbsenceData.service_resource_id

    if (!absenceData) return

    setAbsenceData({
      ...absenceData,
      absence:
      {
        ...absenceData.absence,
        area: absenceData.edit.find((item) => item.service_resource_id === sr)?.area as string,
        area_id: absenceData.edit.find((item) => item.service_resource_id === sr)?.area_id as string,
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAbsenceData.service_resource])

  useEffect(() => {
    if (newAbsenceData.type === 'Range') {
      let startDate = typeof newAbsenceData.absence_start === 'string' ? moment(newAbsenceData.absence_start, 'MM/DD/YYYY hh:mm A').format('YYYY/MM/DD') : moment(newAbsenceData.absence_start).format('YYYY/MM/DD')
      let endDate = typeof newAbsenceData.absence_end === 'string' ? moment(newAbsenceData.absence_end, 'MM/DD/YYYY hh:mm A').format('YYYY/MM/DD') : moment(newAbsenceData.absence_end).format('YYYY/MM/DD')

      if (startDate === endDate) {
        let startTime = typeof newAbsenceData.absence_start === 'string' ? moment(newAbsenceData.absence_start, 'MM/DD/YYYY hh:mm A').format('HH:mm') : moment(newAbsenceData.absence_start).format('HH:mm')
        let endTime = typeof newAbsenceData.absence_end === 'string' ? moment(newAbsenceData.absence_end, 'MM/DD/YYYY hh:mm A').format('HH:mm') : moment(newAbsenceData.absence_end).format('HH:mm')

        if (startTime >= endTime) {
          setErrorTime(true)
        } else {
          setErrorTime(false)
        }
      } else if (startDate > endDate) {
        setErrorTime(true)
      } else {
        setErrorTime(false)
      }
    } else {
      setErrorTime(false)
    }
  }, [newAbsenceData])

  // Render function
  return (<>
    {absenceData ? (
      <div className="AbsencesPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Absence</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'absences',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                absenceData?.permissions.absence_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        {
          newAbsenceData.type === 'Range' ?

            <div className="fieldset">
              <div className="legend">Main</div>

              <div className="fields">
                <div className="__left">
                  <div className="field" style={{ zIndex: 6 }}>
                    <span>Service resource:</span>

                    <input
                      type="text"
                      defaultValue={newAbsenceData.service_resource_code ? `${newAbsenceData.service_resource} (${newAbsenceData.service_resource_code})` : newAbsenceData.service_resource}
                      disabled={true}
                    />
                  </div>

                  <div className="field">
                    <span>Area:</span>

                    <input type="text" defaultValue={newAbsenceData.area} disabled={true} />
                  </div>

                  <div className="field">
                    <span>Type:</span>
                    <Select
                      disabled={true}
                      options={['Range', 'Timeslot'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newAbsenceData.type as string}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Absence start:</span>

                    <Datetime
                      className={classNames({
                        error: errorTime
                      })}
                      dateFormat="MM/DD/YYYY"
                      timeFormat="hh:mm A"
                      inputProps={{ disabled: !editing }}
                      value={newAbsenceData.absence_start as Date}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, absence_start: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Absence end:</span>
                    <Datetime
                      className={classNames({
                        error: errorTime
                      })}
                      dateFormat="MM/DD/YYYY"
                      timeFormat="hh:mm A"
                      inputProps={{ disabled: !editing }}
                      value={newAbsenceData.absence_end as Date}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, absence_end: value })}
                    />
                  </div>
                </div>
              </div>
            </div> :

            <>
              {
                width >= 778 ?
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 300px)', }}>
                      <div className="fieldset">
                        <div className="legend">Main</div>

                        <div className="fields">
                          <div className="__left">
                            <div className="field" style={{ zIndex: 6 }}>
                              <span>Service resource:</span>

                              <input
                                type="text"
                                defaultValue={newAbsenceData.service_resource_code ? `${newAbsenceData.service_resource} (${newAbsenceData.service_resource_code})` : newAbsenceData.service_resource}
                                disabled={true}
                              />
                            </div>

                            <div className="field">
                              <span>Area:</span>

                              <input type="text" defaultValue={newAbsenceData.area} disabled={true} />
                            </div>

                            <div className="field">
                              <span>Type:</span>
                              <Select
                                disabled={!editing}
                                options={['Range', 'Timeslot'].map((option) => ({
                                  span: option,
                                  value: option
                                }))}
                                selectedOption={newAbsenceData.type as string}
                                onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                            </div>
                          </div>

                          <div className="__right">
                            <div className="field">
                              <span>Date:</span>
                              <Datetime
                                dateFormat={true}
                                timeFormat={false}
                                inputProps={{ disabled: !editing }}
                                value={newAbsenceData.date as Date}
                                onChange={(value) => setNewAbsenceData({ ...newAbsenceData, date: moment.isMoment(value) ? value.toDate() : newAbsenceData.date })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fieldset">
                        <div className="legend">Note</div>

                        <div className="fields">
                          <div className="__left">
                            <div className="field">
                              <span>Note:</span>
                              <input
                                type="text"
                                disabled={!editing}
                                value={newAbsenceData.note || ''}
                                onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fieldset">
                        <div className="legend">Info</div>

                        <div className="fields">
                          <div className="__left">
                            <div className="field">
                              <span>Date Created:</span>
                              <input
                                type="text"
                                defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.created_at)}
                                disabled={true}
                              />
                            </div>

                            <div className="field">
                              <span>Created by:</span>
                              <input type="text" defaultValue={absenceData?.absence.created_by} disabled={true} />
                            </div>
                          </div>


                          <div className="__right">
                            <div className="field">
                              <span>Last Edited:</span>
                              <input
                                type="text"
                                defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.updated_at)}
                                disabled={true}
                              />
                            </div>

                            <div className="field">
                              <span>Last Edited by:</span>
                              <input type="text" defaultValue={absenceData?.absence.updated_by} disabled={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="fieldset"
                      style={{ width: '300px' }}
                    >
                      <div className="legend">Time slots:</div>
                      <div className="field_time_slots">
                        {
                          isLoadTimeSlots &&
                          <div className="time_slots">
                            {
                              timeSlots.length ? timeSlots.map(item => (
                                <div
                                  key={item.schedule_id}
                                  className={classNames('time_slots_item',
                                    {
                                      selected: selectedTimeSlot.includes(item.schedule_id),
                                    }
                                  )}
                                  onClick={() => editing && handleSelectedTimeSlot(item.schedule_id)}
                                >
                                  <p> {item.from} - {item.to} </p>
                                </div>
                              )) :
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    color: '#FF0000',
                                    margin: 0
                                  }}
                                >
                                  There are no available timeslots for this day. Please check another date.
                                </p>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div> :
                  <>
                    <div className="fieldset">
                      <div className="legend">Main</div>

                      <div className="fields">
                        <div className="__left">
                          <div className="field" style={{ zIndex: 6 }}>
                            <span>Service resource:</span>

                            <input
                              type="text"
                              defaultValue={newAbsenceData.service_resource_code ? `${newAbsenceData.service_resource} (${newAbsenceData.service_resource_code})` : newAbsenceData.service_resource}
                              disabled={true}
                            />
                          </div>

                          <div className="field">
                            <span>Area:</span>

                            <input type="text" defaultValue={newAbsenceData.area} disabled={true} />
                          </div>

                          <div className="field">
                            <span>Type:</span>
                            <Select
                              disabled={!editing}
                              options={['Range', 'Timeslot'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={newAbsenceData.type as string}
                              onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                          </div>
                        </div>

                        <div className="__right">
                          <div className="field">
                            <span>Date:</span>
                            <Datetime
                              dateFormat={true}
                              timeFormat={false}
                              inputProps={{ disabled: !editing }}
                              value={newAbsenceData.date as Date}
                              onChange={(value) => setNewAbsenceData({ ...newAbsenceData, date: moment.isMoment(value) ? value.toDate() : newAbsenceData.date })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="legend">Time slots:</div>
                      <div className="field_time_slots">
                        {
                          isLoadTimeSlots &&
                          <div className="time_slots">
                            {
                              timeSlots.length ? timeSlots.map(item => (
                                <div
                                  key={item.schedule_id}
                                  className={classNames('time_slots_item',
                                    {
                                      selected: selectedTimeSlot.includes(item.schedule_id),
                                    }
                                  )}
                                  onClick={() => editing && handleSelectedTimeSlot(item.schedule_id)}
                                >
                                  <p> {item.from} - {item.to} </p>
                                </div>
                              )) :
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    color: '#FF0000',
                                    margin: 0
                                  }}
                                >
                                  There are no available timeslots for this day. Please check another date.
                                </p>
                            }
                          </div>
                        }
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="legend">Note</div>

                      <div className="fields">
                        <div className="__left">
                          <div className="field">
                            <span>Note:</span>
                            <input
                              type="text"
                              disabled={!editing}
                              value={newAbsenceData.note || ''}
                              onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="legend">Info</div>

                      <div className="fields">
                        <div className="__left">
                          <div className="field">
                            <span>Date Created:</span>
                            <input
                              type="text"
                              defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.created_at)}
                              disabled={true}
                            />
                          </div>

                          <div className="field">
                            <span>Created by:</span>
                            <input type="text" defaultValue={absenceData?.absence.created_by} disabled={true} />
                          </div>
                        </div>


                        <div className="__right">
                          <div className="field">
                            <span>Last Edited:</span>
                            <input
                              type="text"
                              defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.updated_at)}
                              disabled={true}
                            />
                          </div>

                          <div className="field">
                            <span>Last Edited by:</span>
                            <input type="text" defaultValue={absenceData?.absence.updated_by} disabled={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }
            </>
        }

        {
          newAbsenceData.type === 'Range' &&
          <div className="fieldset">
            <div className="legend">Note</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Note:</span>
                  <input type="text"
                    disabled={!editing}
                    value={newAbsenceData.note || ''}
                    onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {
          newAbsenceData.type === 'Range' &&
          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input type="text" defaultValue={absenceData?.absence.created_by} disabled={true} />
                </div>
              </div>


              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.absence.updated_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Last Edited by:</span>
                  <input type="text" defaultValue={absenceData?.absence.updated_by} disabled={true} />
                </div>
              </div>
            </div>
          </div>
        }

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                absenceData.permissions.absence_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Absence</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || (isLoadTimeSlots && !selectedTimeSlot.length) || isSaving || errorTime}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {absenceData.permissions.absence_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Absence
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the absence it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_Item
