import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import moment from "moment"
import { DateTime } from "luxon";
import { Transition } from "react-transition-group"
import qs from "qs";
import { useCookies } from 'react-cookie';

import Checkbox from "../../components/Checkbox"
import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Select from "../../components/Select"

import { getDateRangeByPreset } from "../../funcs/reports"
import { httpClientUpdate, nErrorUpdate, usePrevious } from "../../funcs";
import { useAppSelector } from '../../store/hooks'

import { CallSource } from "../../models/Calls"
import { DateRangePreset } from "../../models/Misc"
import { CallsOptionsProps } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsSourcesReport
}

interface CallsSourcesReport {

  interface: {

    tag_words: string[],

    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  dashboard: {

    calls: string,
    callers: string,
    missed_calls: string,
    active_calls: string
  },

  sources: CallSource[]

  edit: {
    call_centers: {
      call_center_id: string
      name: string
    }[]
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CallsSourcesPage_List() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [callsOptions, setCallsOptions] = useState<CallsOptionsProps[]>([])

  const [topFilterShown, setTopFilterShown] = useState(false)
  const [hashtagFilterShown, setHashtagFilterShown] = useState(false)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [selectedCallCenter, setSelectedCallCenter] = useState({
    call_center_id: '',
    name: '',
  })

  const prevSelectedCallCenter = usePrevious(selectedCallCenter)

  const [reportData, setReportData] = useState<CallsSourcesReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.calls_source?.filter_field ? {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
      ...cookiesSetting.calls_source.filter_field,
    } : {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
    },
    tag_words: [] as string[],
    searchFilters: [["source", "system"], ["inbound", "outbound"]],
    selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },
    sort: {
      field: cookiesSetting && cookiesSetting?.calls_source?.sort_field ? cookiesSetting.calls_source.sort_field : 'source',
      direction: cookiesSetting && cookiesSetting?.calls_source?.sort_type ? cookiesSetting.calls_source.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Source',
      value: 'source'
    }, {
      span: 'Total calls',
      value: 'total_calls'
    }, {
      span: 'Completed',
      value: 'completed_calls'
    }, {
      span: 'Unanswered',
      value: 'unanswered_calls'
    }, {
      span: 'Avg Duration',
      value: 'avg_duration'
    }, {
      span: 'Total appointments',
      value: 'total_appointments'
    }]

  })

  useEffect(() => {
    if ($router.route.params.options) {
      setCallsOptions($router.route.params.options)
    } else {
      $router.router.navigate('calls', {
        companyId: activeAccountId,
      }, { reload: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load calls function
  async function loadCalls() {
    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key]);

    let reqData: {
      account_id: string | null
      limit_rows: string
      page: string
      date_start: Date
      date_end: Date
      filter_tag: string
      main_filter: string
      sort_field: string
      sort_type: string
      filters: string
      call_center_id?: string
    } = {
      account_id: activeAccountId,
      limit_rows: cookiesSetting && cookiesSetting?.calls_source?.limit_rows ? JSON.stringify(cookiesSetting?.calls_source?.limit_rows) : JSON.stringify(reportsMaxRows),
      page: JSON.stringify(localInterface.page),
      date_start: localInterface.min_date,
      date_end: localInterface.max_date,
      filter_tag: JSON.stringify(localInterface.tag_words),
      main_filter: JSON.stringify(localInterface.selectedSearchFilters),
      sort_field: localInterface.sort.field,
      sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
      filters: activeFilters,
      ...(localInterface.search ? { search: localInterface.search } : {})
    };

    if (selectedCallCenter.call_center_id) {
      reqData.call_center_id = selectedCallCenter.call_center_id
    }

    setCookie('settings', { ...cookiesSetting, calls_source: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.calls_source?.limit_rows ? cookiesSetting?.calls_source.limit_rows : reportsMaxRows } })

    try {
      const { data: { data: calls, success, error } } = (await httpClientUpdate.post('/calls/report/sources', qs.stringify(reqData))) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData({
          ...calls,

          interface: {

            ...calls.interface,
            min_date: moment(calls.interface.min_date).startOf('day').toDate(),
            max_date: moment(calls.interface.max_date).endOf('day').toDate(),
          }
        })

        if (calls?.edit?.call_centers.length > 1 && selectedCallCenter.call_center_id === '') {
          setSelectedCallCenter({
            name: calls.edit.call_centers[0].name,
            call_center_id: calls.edit.call_centers[0].call_center_id
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, calls_source: {  } })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadCalls()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  useEffect(() => {
    if (!!selectedCallCenter.call_center_id && !!prevSelectedCallCenter?.call_center_id) {
      loadCalls()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCallCenter.call_center_id])

  // Is tag_word selected function
  function isTagWordSelected(value: string) {
    return localInterface.tag_words.includes(value)
  }

  // Is all tag words selected function
  function isAllTagWordsSelected() {
    return localInterface.tag_words.length === 0
  }

  // Toggle tagword function
  function switchTagWord(value: string, toggle: boolean) {

    let _arr = [...localInterface.tag_words]

    if (toggle && value === "All") {
      _arr = []
    }
    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }
    else {

      if (reportData?.interface.tag_words.every((option) => _arr.concat([value]).includes(option))) {
        _arr = []
      }

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, tag_words: _arr })
  }

  // Is search field selected function
  function isSearchFilterSelected(i: number, value: string, field: string) {

    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.includes(value);
    }
    if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.includes(value);
    }
  }

  // Is all search fields selected function
  function isAllSearchFiltersSelected(i: number, field: string) {
    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.length === 0
    } else if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.length === 0
    }

  }

  // Toggle search filter function
  function switchSearchFilter(i: number, value: string, toggle: boolean, field: string, $arr: string[]) {
    let $arrType = $arr;
    let $object = localInterface.selectedSearchFilters;
    if (toggle && value === "All") {
      $arrType = [];
    }
    else if (toggle === false) {
      for (let i = 0; i < $arrType.length; i++) {
        // eslint-disable-next-line no-loop-func
        $arrType.forEach((v, index) => {
          if (v === value) {
            $arrType.splice(index, 1)
          }
        })
      }
    } else {
      $arrType.push(value);
    }

    if (field === 'type') {
      if ($arrType.length === localInterface.searchFilters[0].length) {
        $arrType = [];
      }
      $object.type = $arrType;
    }
    if (field === 'direction') {
      if ($arrType.length === localInterface.searchFilters[1].length) {
        $arrType = [];
      }
      $object.direction = $arrType;
    }
    setLocalInterface({ ...localInterface, selectedSearchFilters: $object })
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="CallsSourcesPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">

          <div className="cell">

            <div className="amount">{reportData.dashboard.calls}</div>
            <div className="legend">Calls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.callers}</div>
            <div className="legend">Callers</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.missed_calls}</div>
            <div className="legend">Missed Calls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.active_calls}</div>
            <div className="legend">Active Calls</div>
          </div>
        </div>

        { /* Page header */}
        <div className="page-header">
          <h1>Sources</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}

          childrenAfterSearch={(<>
            <div tabIndex={-1} className="filter-button" onFocus={() => setTopFilterShown(true)} onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setTopFilterShown(false)}>
              <button className={classNames("_wa", "_rounded", "_iconed", {
                _blue: !isAllSearchFiltersSelected(0, 'type') || !isAllSearchFiltersSelected(1, 'direction')
              })}>
                <Icon icon="filter-8" />
              </button>

              <Transition in={topFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                {(state) => (
                  <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                    <Checkbox contents="All" value={isAllSearchFiltersSelected(0, 'type')} onChange={(value) => switchSearchFilter(0, "All", value, 'type', localInterface.selectedSearchFilters.type)} />
                    {localInterface.searchFilters[0].map((option, index) => (
                      <Checkbox
                        key={index}
                        contents={option}
                        value={isSearchFilterSelected(0, option, 'type')}
                        onChange={(value) => switchSearchFilter(1, option, value, 'type', localInterface.selectedSearchFilters.type)}
                      />
                    ))}
                    <hr />
                    <Checkbox contents="All" value={isAllSearchFiltersSelected(1, 'direction')} onChange={(value) => switchSearchFilter(1, "All", value, 'direction', localInterface.selectedSearchFilters.direction)} />
                    {localInterface.searchFilters[1].map((option, index) => (
                      <Checkbox
                        key={index}
                        contents={option}
                        value={isSearchFilterSelected(1, option, 'direction')}
                        onChange={(value) => switchSearchFilter(1, option, value, 'direction', localInterface.selectedSearchFilters.direction)}
                      />
                    ))}
                    <button onClick={() => {
                      $setUpdater(Math.random());
                      setTopFilterShown(false)
                    }} className="_bordered _blue _wa">
                      Filter
                    </button>
                  </div>
                )}
              </Transition>
            </div>

            <div
              tabIndex={-1}
              className="filter-button"
              onFocus={() => setHashtagFilterShown(true)}
              onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setHashtagFilterShown(false)}>
              <button className={classNames("_wa", "_rounded", "_iconed", {
                _blue: !isAllTagWordsSelected()
              })}>
                <Icon icon="hashtag-1" />
              </button>

              <Transition in={hashtagFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                {(state) => (
                  <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                    <Checkbox contents="All" value={isAllTagWordsSelected()} onChange={(value) => switchTagWord("All", value)} />
                    {reportData.interface.tag_words.map((option, i) => (
                      <Checkbox contents={option} key={i} value={isTagWordSelected(option)} onChange={(value) => switchTagWord(option, value)} />
                    ))}
                    <button className="_bordered _blue _wa">
                      Filter
                    </button>
                  </div>
                )}
              </Transition>
            </div>

            {
              reportData?.edit?.call_centers.length > 1 &&
              <Select
                options={reportData.edit.call_centers.map((item) => ({
                  span: item.name,
                  value: item.call_center_id,
                }))}
                selectedOption={selectedCallCenter.call_center_id as string}
                onChange={(value: string) => setSelectedCallCenter({ ...selectedCallCenter, call_center_id: value })}
              />
            }
          </>)}

          childrenBeforeDateType={(<>
            <div>
              <Select
                zeroed={true}
                options={callsOptions}
                selectedOption="calls.sources"
                onChange={(value) => $router.router.navigate(value as string, {
                  companyId: activeAccountId,
                  options: callsOptions,
                }, { reload: true })}
              />
            </div>
          </>)}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.calls_source?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, calls_source: { ...cookiesSetting.calls_source, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Source</span>)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Total calls</span>)}

                  sortDirection={localInterface.sort.field === 'total_calls' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_calls', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Completed</span>)}

                  sortDirection={localInterface.sort.field === 'completed_calls' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'completed_calls', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Unanswered</span>)}

                  sortDirection={localInterface.sort.field === 'unanswered_calls' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'unanswered_calls', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Avg Duration</span>)}

                  sortDirection={localInterface.sort.field === 'avg_duration' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'avg_duration', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Total appointments</span>)}

                  sortDirection={localInterface.sort.field === 'total_appointments' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_appointments', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.sources.map((callSource, i) => (
              <tr key={i}>
                <td>{callSource.source}</td>
                <td>{callSource.total_calls}</td>
                <td>{callSource.completed_calls}</td>
                <td>{callSource.unanswered_calls}</td>
                <td>{callSource.avg_duration}</td>
                <td>{callSource.total_appointments}</td>
              </tr>
            ))}
          </table>

          {/* Medium desktop table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.sources.map((callSource, i) => (
              <tr key={i}>
                <td>
                  <div>{callSource.source}</div>
                </td>
                <td>
                  <div>Total calls: {callSource.total_calls}</div>
                  <div>Avg Duration: {callSource.avg_duration}</div>
                </td>
                <td>
                  <div>Completed: {callSource.completed_calls}</div>
                  <div>Unanswered: {callSource.unanswered_calls}</div>
                </td>
                <td>
                  <div>Total appointments: {callSource.total_appointments}</div>
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.sources.map((callSource, i: number) => (
              <div className="item nogap negativeGap" key={i}>
                <div className="__top">
                  <div className="__left">
                    <div>
                      <strong>{callSource.source}</strong>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Total calls: <span className="fw500">{callSource.total_calls}</span>
                    </div>
                    <div>
                      Completed: <span className="green fw500">{callSource.completed_calls}</span>
                    </div>
                    <div>
                      Unanswered: <span className="red fw500">{callSource.unanswered_calls}</span>
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Avg Duration:</span>
                      <span> {callSource.avg_duration}</span>
                    </div>
                    <div>
                      <span className="gray">Total Appointments:</span>
                      <span> {callSource.total_appointments}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.calls_source?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, calls_source: { ...cookiesSetting.calls_source, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default CallsSourcesPage_List
