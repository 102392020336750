import React, { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../../components/Icon'
import Select from "../../components/Select"

import { useAppSelector } from '../../store/hooks'
import { DayOfWeekOptionsForSchedule, arrTimesForSchedule } from '../../constans'
import { httpClientUpdate, nErrorUpdate, sortServiceResourceSchedule } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface ScheduleProps {
  day: string
  from: string
  schedule_id: string
  // limit: number
  to: string,
  editing?: boolean
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    schedule: ScheduleProps[]
  }
}

interface SettingsScheduleProps {
  handleBackSettings: () => void,
  permissions?: {
    call_center_schedule_add: boolean
    call_center_schedule_delete: boolean
    call_center_schedule_edit: boolean
    call_center_schedule_show: boolean

    call_center_settings_edit: boolean
    call_center_settings_show: boolean

    twilio_info_edit: boolean
    twilio_info_show: boolean
  }
}

export default function Schedule({
  handleBackSettings,
  permissions
}: SettingsScheduleProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [addNew, setAddNew] = useState(false)
  const [scheduleData, setScheduleData] = useState<ScheduleProps[] | []>([])

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: scheduleData, success, error } } = (await httpClientUpdate.get('/callcenter/schedule', {
        params: {
          account_id: activeAccountId,
          call_center_id: $router.router.getState().params.callCenterId,
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

        let sortScheduleByDay = sortServiceResourceSchedule(scheduleData.schedule)

        setScheduleData(sortScheduleByDay.map(item => {
          return {
            ...item,
            day: capitalize(item.day),
            editing: false
          }
        }))
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load schedule data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // add new schedule
  async function handleAddNewSchedule(i: number) {
    setIsSaving(true)
    try {
      const { day, from, to } = scheduleData[i]
      const response = await httpClientUpdate.post(`/callcenter/schedule`, qs.stringify({
        account_id: activeAccountId,
        day,
        from,
        to,
        call_center_id: $router.router.getState().params.callCenterId,
      }))
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
        setAddNew(false)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove schedule
  async function removeSchedule(i: number) {
    setIsSaving(true)
    try {
      const { schedule_id } = scheduleData[i]
      const response = await httpClientUpdate.delete(`/callcenter/schedule/${schedule_id}`, {
        data: {
          account_id: activeAccountId,
          call_center_id: $router.router.getState().params.callCenterId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // change schedule
  async function handleSaveChangeSchedule(i: number) {
    setIsSaving(true)
    try {
      const { day, from, to, schedule_id } = scheduleData[i]

      const response = await httpClientUpdate.put(`/callcenter/schedule/${schedule_id}`, qs.stringify({
        account_id: activeAccountId,
        day,
        from,
        to,
        call_center_id: $router.router.getState().params.callCenterId,
      }))
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleChangeScheduleData(value: string, name: string, idx: number) {
    let updateScheduleData = scheduleData.map((item, index) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    setScheduleData(updateScheduleData)
  }

  function handleAddSchedule() {
    let updateScheduleData = scheduleData.map(item => {
      return {
        ...item,
        editing: false
      }
    })
    updateScheduleData.push({
      day: '',
      from: '',
      to: '',
      schedule_id: '',
      editing: true,
    })
    setScheduleData(updateScheduleData)
    setAddNew(true)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...scheduleData]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setScheduleData(_items)
  }

  function removeNewSchedule(index: number) {
    let updateScheduleData = scheduleData.filter((item, idx) => idx !== index)
    setScheduleData(updateScheduleData)
    setAddNew(false)
  }

  return (
    <div className="SchedulePageList_List entity-edit">

      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Call Center Schedule</h1>
        </div>
        {/* Temporarily removed the rights check on the field edit_service_resource */}
        <div style={{ display: 'flex' }}>
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
            className="_wa"
            onClick={() => handleBackSettings()}
          >
            Back
          </button>

          {
            permissions?.call_center_schedule_add &&
            <button className="_wa _green" onClick={() => handleAddSchedule()}>
              Add
            </button>
          }
        </div>
      </div>

      {/* Table */}
      <div className="fieldset">
        {!!scheduleData.length &&
          permissions?.call_center_schedule_show &&
          < table className="table">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Day</th>
                <th style={{ width: '30%' }}>From</th>
                <th style={{ width: '30%' }}>To</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {scheduleData.map((item, i) => (<React.Fragment key={i}>
              {item.editing && permissions.call_center_schedule_edit ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={DayOfWeekOptionsForSchedule.map((option) => ({
                          span: option.value,
                          value: option.value
                        }))}
                        selectedOption={item.day as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'day', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={arrTimesForSchedule.map((option) => ({
                          span: option.span,
                          value: option.value
                        }))}
                        selectedOption={item.from as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'from', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={
                          item.from === '' ?
                            arrTimesForSchedule.map((option) => ({
                              span: option.span,
                              value: option.value
                            })) :
                            arrTimesForSchedule.filter((option) => item.from < option.value && ({
                              span: option.span,
                              value: option.value
                            }))
                        }
                        selectedOption={item.to as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'to', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={(addNew && (scheduleData[i].day === '' || scheduleData[i].from === '' || scheduleData[i].to === '')) || isSaving}
                      onClick={() => addNew ? handleAddNewSchedule(i) : handleSaveChangeSchedule(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.call_center_schedule_delete || isSaving}
                      onClick={() => addNew ? removeNewSchedule(i) : removeSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.day}</td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.from).length ?
                      arrTimesForSchedule.filter(time => time.value === item.from)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.to).length ?
                      arrTimesForSchedule.filter(time => time.value === item.to)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissions.call_center_schedule_edit}
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.call_center_schedule_delete || isSaving}
                      onClick={() => removeSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>}
      </div>
    </div>
  )
}
