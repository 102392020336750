import { useEffect, useState, useCallback, useRef } from "react";
import { useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import ReactCrop, { Crop } from "react-image-crop"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher"

import { formatPhoneNumberToServerString, httpClientUpdate, isValidURL, nErrorUpdate, validateEmail } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import AccountCustom from "../../models/AccountCustom"
import { AccountsReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountsReport
}

function generateDownload(canvas: {
  toDataURL(arg0: string, arg1: number): any; toBlob: (arg0: (blob: Blob | MediaSource) => void, arg1: string, arg2: number) => void;
}, crop: any) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob: Blob | MediaSource) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
    },
    'image/png',
    1
  );
  return canvas.toDataURL("image/jpeg", 0.95)
}

function setCanvasImage(image: { naturalWidth: number; width: number; naturalHeight: number; height: number; }, canvas: { getContext: (arg0: string) => any; width: number; height: number; }, crop: { width: number; height: number; x: number; y: number; }) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

}

function AccountsPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false)

  const [upImg, setUpImg] = useState('');

  const [crop, setCrop] = useState<Partial<Crop>>({ unit: 'px', width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const [inputTypes, setInputTypes] = useState({
    api_token: 'password' as 'text' | 'password',
  })

  const [readyToSave, setReadyToSave] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const [avatarPopup, setAvatarPopup] = useState(false)

  const [editions, setEditions] = useState<{
    time_zone: {
      id: number
      name: string
    }[],
    source: {
      list_sources_id: string
      name: string
    }[],
    status: {
      id: number
      name: string
    }[],
    owners: {
      user: string,
      user_id: string
    }[],
    permissions: {
      accounts_permissions_id: string
      name: string
    }[],
    state: {
      id: number
      name: string
    }[],
    accounts_menus: {
      accounts_menus_id: string
      name: string
    }[]
  } | null>(null)

  const [newAccountData, setNewAccountData] = useState<Partial<AccountCustom>>({

  })
  const [draging, setDraging] = useState(false)

  const [ownerData, setOwnerData] = useState({
    first_name: '',
    last_name: '',
    phone: '+1',
    email: ''
  })

  // save document
  async function handleSave() {
    try {
      setIsSaving(true)
      const {
        name, status, email, phone,
        slack_active, slack_url, billing_street, billing_unit,
        billing_state, billing_city, billing_zip,
        mailing_city, mailing_street, mailing_unit,
        mailing_zip, same_address, time_zone, source,
        mailing_state, permission, owner, accounts_menus_id, api_token
      } = newAccountData

      const response = await httpClientUpdate.post(`/accounts`, {
        account_id: activeAccountId,
        name,
        status,
        logo: previewCanvasRef.current && upImg ? generateDownload(previewCanvasRef.current, crop) : '',
        email: email ? email : ' ',
        phone: phone ? formatPhoneNumberToServerString(phone) : " ",
        slack_url: slack_url ? slack_url : " ",
        time_zone_id: time_zone ? time_zone : null,
        owner_id: owner ? owner : '887766', // required
        source_id: source ? source : null,
        accounts_permissions_id: permission ? permission : null,
        accounts_menus_id: accounts_menus_id ? accounts_menus_id : null,
        is_slack: slack_active ? 1 : 0,
        address_street: mailing_street ? mailing_street : ' ',
        address_state_id: mailing_state ? mailing_state : null,
        address_unit: mailing_unit ? mailing_unit : ' ',
        address_zip: mailing_zip ? mailing_zip : ' ',
        address_city: mailing_city ? mailing_city : ' ',
        billing_address_state_id: same_address ? mailing_state ? mailing_state : null : billing_state ? billing_state : null,
        billing_address_street: same_address ? mailing_street : billing_street, // required
        billing_address_unit: same_address ? mailing_unit : billing_unit, // required
        billing_address_city: same_address ? mailing_city : billing_city, // required
        billing_address_zip: same_address ? mailing_zip : billing_zip, // required
        is_same_address: same_address ? 1 : 0,
        api_token
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('accounts', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: accountsData, success, error } } = (await httpClientUpdate.post('/accounts/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (accountsData.permissions.account_add) {
          setEditions(accountsData.edit)
          setNewAccountData({
            name: '',
            owner: '',
            phone: '',
            status: '',
            email: '',
            source: '',
            users: '',
            phone_numbers: '',
            slack_url: '',
            slack_active: true,
            mailing_street: '',
            mailing_unit: '',
            mailing_city: '',
            mailing_zip: '',
            mailing_state: accountsData.edit.state[0].id,
            billing_street: '',
            billing_unit: '',
            billing_city: '',
            billing_zip: '',
            billing_state: accountsData.edit.state[0].id,
            same_address: true,
            api_token: '',
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load account data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // On select file function
  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onDragEnter = useCallback((e) => {
    setDraging(true);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragover', onDragOver);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragover', onDragOver);
    };
  }, [onDragEnter, onDragLeave, onDragOver]);

  useEffect(() => setReadyToSave(true), [
    newAccountData
  ])

  function checkSave() {
    let errors = []
    if (newAccountData.slack_active) {
      if (newAccountData.slack_url === '') {
        errors.push('slack_url')
      } else {
        let isValid = newAccountData.slack_url && isValidURL(newAccountData.slack_url)
        !isValid && errors.push('slack_url')
      }
    }
    let emailValid = newAccountData.email && validateEmail(newAccountData.email)
    !emailValid && errors.push('email_invalid')
    return errors
  }

  function preSaveFunction() {
    if (checkSave().length) {
      setErrors(checkSave())
      setReadyToSave(false)
    } else {
      handleSave()
      setErrors([])
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (imgRef.current &&
      previewCanvasRef.current &&
      completedCrop) {

      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }

  }, [completedCrop]);

  // Render function
  return (<>
    {editions ? (
      <div className="AccountsPage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>New account</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('accounts', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Owner</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>First Name:</span>

                <input
                  type="text"
                  defaultValue={ownerData.first_name}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, first_name: value })}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>

                <input
                  type="text"
                  defaultValue={ownerData.last_name}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, last_name: value })}
                />
              </div>
            </div>


            <div className="__right">

              <div className="field">
                <span>Phone:</span>

                <ReactInputMask
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={ownerData.phone}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, phone: value })}
                />
              </div>

              <div className="field">
                <span>Email:</span>

                <input
                  type="text"
                  defaultValue={ownerData.email}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, email: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  type="text"
                  defaultValue={newAccountData.name}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, name: value })}
                />
              </div>

              <div className="field">
                <span>Phone:</span>
                <ReactInputMask
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newAccountData.phone}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, phone: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field" style={{ zIndex: 5 }}>
                <span>Status:</span>
                <Select
                  options={editions.status.map((status) => ({
                    span: status.name,
                    value: status.id
                  }))}
                  selectedOption={newAccountData.status as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, status: value })}
                />
              </div>

              <div className="field">
                <span>Email:</span>
                <input
                  type="text"
                  className={classNames({
                    error: errors.includes('email_invalid'),
                  })}
                  defaultValue={newAccountData.email}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, email: value })}
                />
              </div>

              <div className="field">
                <span>Source:</span>
                <Select
                  options={[{ name: '', list_sources_id: '' }].concat(editions.source).map((source) => ({
                    span: source.name,
                    value: source.list_sources_id
                  }))}
                  selectedOption={newAccountData.source as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, source: value })}
                />
              </div>
            </div>

            <div className="avatar-editing">
              <button className="_zeroed _iconed" onClick={() => setAvatarPopup(true)}>
                {
                  upImg ?
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: '90px',
                        height: '90px',
                      }}
                    /> :
                    newAccountData.logo ?
                      <img src={newAccountData.logo} alt="User avatar" /> :
                      <Icon icon="user-20" />
                }
              </button>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Settings</div>

          <div className="fields">

            <div className="__left">

              <div className="field">
                <span>Permission:</span>
                <Select
                  options={[{ name: '', accounts_permissions_id: '' }].concat(editions.permissions).map((permission) => ({
                    span: permission.name,
                    value: permission.accounts_permissions_id
                  }))}
                  selectedOption={newAccountData.permission as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, permission: value })}
                />
              </div>

              <div className="field">
                <span>Menu:</span>
                <Select
                  options={[{ name: '', accounts_menus_id: '' }].concat(editions.accounts_menus).map((menu) => ({
                    span: menu.name,
                    value: menu.accounts_menus_id
                  }))}
                  selectedOption={newAccountData.accounts_menus_id as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, accounts_menus_id: value })}
                />
              </div>

              <div className="field">
                <span>Slack Url:</span>
                <input
                  type="text"
                  className={classNames({
                    error: errors.includes('slack_url'),
                  })}
                  defaultValue={newAccountData.slack_url}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, slack_url: value })}
                />
              </div>
            </div>

            <div className="__right">

              <div className="field">
                <span>Time Zone:</span>
                <Select
                  options={[{ name: '', id: 0 }].concat(editions.time_zone).map((time_zone) => ({
                    span: time_zone.name,
                    value: time_zone.id
                  }))}
                  selectedOption={newAccountData.time_zone as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, time_zone: value })}
                />
              </div>

              <div className="field">
                <span>Api token:</span>
                <div className="form-field _iconed">
                  <input
                    type={inputTypes.api_token}
                    autoComplete='new-password'
                    defaultValue={newAccountData.api_token || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, api_token: value })}
                  />
                  <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, api_token: inputTypes.api_token === 'text' ? 'password' : 'text' })}>
                    {
                      inputTypes.api_token === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>

              <div className="field">
                <span>Slack:</span>
                <Switcher checked={newAccountData.slack_active} onChange={(value) => setNewAccountData({ ...newAccountData, slack_active: value })} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Mailing Address</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Street:</span>
                <input type="text" defaultValue={newAccountData.mailing_street} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_street: value })} />
              </div>

              <div className="field">
                <span>Unit/Apt:</span>
                <input type="text" defaultValue={newAccountData.mailing_unit} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_unit: value })} />
              </div>

              <div className="field">
                <span>City:</span>
                <input type="text" defaultValue={newAccountData.mailing_city} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_city: value })} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>State:</span>
                <Select options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                  span: option.name,
                  value: option.id
                }))} selectedOption={newAccountData.mailing_state as string} onChange={(value: string) => setNewAccountData({ ...newAccountData, mailing_state: value })} />
              </div>

              <div className="field">
                <span>Zip:</span>
                <input type="text" defaultValue={newAccountData.mailing_zip} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_zip: value })} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="flex-container sb wrap">
            <div className="legend">Billing Address</div>

            <div className="legend-action-wrapper">
              <label>Same as Mailing Address:</label>

              <div className="input-wrapper">
                <Checkbox contents='' value={newAccountData.same_address} onChange={(value) => setNewAccountData({ ...newAccountData, same_address: value })} />
              </div>
            </div>
          </div>

          {!newAccountData.same_address ? (
            <div className="fields">

              <div className="__left">
                <div className="field">
                  <span>Street:</span>
                  <input type="text" defaultValue={newAccountData.billing_street} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_street: value })} />
                </div>

                <div className="field">
                  <span>Unit/Apt:</span>
                  <input type="text" defaultValue={newAccountData.billing_unit} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_unit: value })} />
                </div>

                <div className="field">
                  <span>City:</span>
                  <input type="text" defaultValue={newAccountData.billing_city} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_city: value })} />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>State:</span>
                  <Select options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                    span: option.name,
                    value: option.id
                  }))} selectedOption={newAccountData.billing_state as string} onChange={(value: string) => setNewAccountData({ ...newAccountData, billing_state: value })} />
                </div>

                <div className="field">
                  <span>Zip:</span>
                  <input type="text" defaultValue={newAccountData.billing_zip} onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_zip: value })} />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => $router.router.navigate('accounts', {
                companyId: activeAccountId
              }, {
                reload: true
              })}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSave || isSaving}
              className="_bordered _green"
              onClick={() => preSaveFunction()}
            >
              Save
            </button>
          </div>
        </div>

        {avatarPopup ? (
          <div className="popup imageCrop" onClick={() => setAvatarPopup(false)}>

            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                draging ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      name="myImage"
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onChange={onSelectFile}
                      id="file-uploader"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader')?.click()}
                    >
                      <span>Upload a File</span>
                    </button>
                  </div>
              }

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                {
                  upImg &&
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                }
              </div>

              {
                upImg &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      setUpImg('')
                      setAvatarPopup(false)
                    }}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      setAvatarPopup(false)
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default AccountsPage_New
