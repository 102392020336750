import { useRoute } from "react-router5"

import BookingPage from "../pages/Booking/Booking"
import DashboardPage from "../pages/Dashboard/Dashboard"
import Tools from "../pages/Tools/List"
import ReportsPage from "../pages/Reports/List"
import MailPage from "../pages/Mail/List"
import MailPageItem from "../pages/Mail/Item"
import MailPageNew from "../pages/Mail/New"
import EstimatesPage from "../pages/Estimates/List"
import EstimatesPageItem from "../pages/Estimates/Item"
import DepositsPage from "../pages/Deposits/List"
import DepositsPageItem from "../pages/Deposits/Item"
import DepositsPageNew from "../pages/Deposits/New"
import InvoicesPage from "../pages/Invoices/List"
import InvoicePageItem from "../pages/Invoices/Item"
import PaymentsPageItem from "../pages/Payments/Item"
import PaymentsPage from "../pages/Payments/List"
import PayoutsPage from "../pages/Payouts/List"
import PayoutsPageItem from "../pages/Payouts/Item"
import PayoutsPageNew from "../pages/Payouts/New"
import SchedulePage from "../pages/Schedule/Schedule"
import DispatchersSchedulePage from "../pages/DispatchersSchedule/List"
import GoogleLocalServiceAdsPage from "../pages/GoogleLocalServiceAds/List"
import SmsPage from "../pages/Sms/List"
import SmsPageItem from "../pages/Sms/Item"
import SmsPageItemMessage from "../pages/Sms/ItemMessage"
import TicketsPageList from "../pages/Tickets/List"
import TicketsPageItem from "../pages/Tickets/Item"
import TicketsPageNew from "../pages/Tickets/New"
import SupportPageFaq from "../pages/support/faq"
import SupportPageTickets from "../pages/support/tickets"
import SupportPageContacts from "../pages/support/contacts"
import AppointmentsPageList from "../pages/Appointments/List"
import ClientsPageList from "../pages/Clients/List"
import ConversionsPageList from "../pages/Conversions/List"
import AbsencesPageList from "../pages/Absences/List"
import ListsPageAppliances from "../pages/Lists/Appliances"
import ListsPageBrands from "../pages/Lists/Brands"
import ListsPageSources from "../pages/Lists/Sources"
import ListsPageAreas from "../pages/Lists/Areas"
import ListsPagePaymentsSevices from "../pages/Lists/PaymentsSevices"
import ListsPageCompanies from "../pages/Lists/Companies"
import ListsPageCampaigns from "../pages/Lists/Campaigns"
import ListsPageWarehouses from "../pages/Lists/Warehouses"
import ListsPageSpamFilters from "../pages/Lists/SpamFilters"
import ListsPageTicketTemplates from "../pages/Lists/TicketTemplates"
import JobsPageList from "../pages/Jobs/List"
import WebsiteRequestPageList from "../pages/WebsiteRequest/List"
import AbsencesPageItem from "../pages/Absences/Item"
import JobsPageItem from "../pages/Jobs/Item"
import WebsiteRequestPageItem from "../pages/WebsiteRequest/Item"
import JobsPageItemTech from "../pages/Jobs/ItemTech"
import AbsencePageItemTech from "../pages/Absences/ItemTech"
import CallsPageList from "../pages/Calls/List"
import CallsSourcesPageList from "../pages/Calls/SourcesList"
import CallsNumbersPageList from "../pages/Calls/NumbersList"
import CallsAreaPageList from "../pages/Calls/Area"
import PermissionsPageList from "../pages/Permissions/List"
import PermissionsPageItem from "../pages/Permissions/Item"
import PermissionsPageNew from "../pages/Permissions/New"
import NotifyTemplatesPageList from "../pages/NotifyTemplates/List"
import NotifyTemplatesPageItem from "../pages/NotifyTemplates/Item"
import NotifyTemplatesPageNew from "../pages/NotifyTemplates/New"
import NotifyResourcesPageList from "../pages/NotifyResources/List"
import NotifyResourcesPageItem from "../pages/NotifyResources/Item"
import NotifyResourcesPageNew from "../pages/NotifyResources/New"
import MailboxesPageList from "../pages/Mailboxes/List"
import MailboxesPageItem from "../pages/Mailboxes/Item"
import MailboxesPageNew from "../pages/Mailboxes/New"
import GoogleAccountsPageList from "../pages/GoogleAccounts/List"
import GoogleAccountsPageItem from "../pages/GoogleAccounts/Item"
import GoogleAccountsPageNew from "../pages/GoogleAccounts/New"
import GoogleLocalServicesPageList from "../pages/GoogleLocalServices/List"
import GoogleLocalServicesPageItem from "../pages/GoogleLocalServices/Item"
import GoogleLocalServicesPageNew from "../pages/GoogleLocalServices/New"
import UsersPageList from "../pages/Users/List"
import UsersPageNew from "../pages/Users/New"
import UsersPageItem from "../pages/Users/Item"
import UsersMenusPageList from "../pages/UsersMenus/List"
import UsersMenusPageNew from "../pages/UsersMenus/New"
import UsersMenusPageItem from "../pages/UsersMenus/Item"
import AbsencesPageNew from "../pages/Absences/New"
import UserGroupsPageList from "../pages/UserGroups/List"
import UserGroupsPageItem from "../pages/UserGroups/Item"
import UserGroupsPageNew from "../pages/UserGroups/New"
import CallCenterPhoneNumbersPageList from "../pages/CallCenter/PhoneNumbers/List"
import CallCenterDispatchersPageList from "../pages/CallCenter/Dispatchers/List"
import CallCenterAbsencePageList from "../pages/CallCenter/Absence/List"
import CallCenterAbsencePageNew from "../pages/CallCenter/Absence/New"
import CallCenterAbsencePageItem from "../pages/CallCenter/Absence/Item"
import CallCenterAdditionalTimePageList from "../pages/CallCenter/AdditionalTime/List"
import CallCenterAdditionalTimePageItem from "../pages/CallCenter/AdditionalTime/Item"
import CallCenterAdditionalTimePageNew from "../pages/CallCenter/AdditionalTime/New"
import CallCenterCallGroupsPageList from "../pages/CallCenter/CallGroups/List"
import CallCenterTemporaryClosedPageList from "../pages/CallCenter/TemporaryClosed/List"
import CallCenterTemporaryClosedPageItem from "../pages/CallCenter/TemporaryClosed/Item"
import CallCenterTemporaryClosedPageNew from "../pages/CallCenter/TemporaryClosed/New"
import CallCenterCallerGroupsPageList from "../pages/CallCenter/CallerGroups/List"
import CallCenterCallCentersPageList from "../pages/CallCenter/CallCenters/List"
import CallCenterCallCentersPageItem from "../pages/CallCenter/CallCenters/Item"
import CallCenterCallCentersPageNew from "../pages/CallCenter/CallCenters/New"
import CallCenterAudioLibraryPageList from "../pages/CallCenter/AudioLibrary/List"
import CallCenterAudioLibraryPageItem from "../pages/CallCenter/AudioLibrary/Item"
import CallCenterAudioLibraryPageNew from "../pages/CallCenter/AudioLibrary/New"
import CallCenterCallFlowsPageList from "../pages/CallCenter/CallFlows/List"
import CallCenterPhoneNumbersPageNew from "../pages/CallCenter/PhoneNumbers/New"
import CallCenterDispatchersPageNew from "../pages/CallCenter/Dispatchers/New"
import CallCenterCallGroupsPageNew from "../pages/CallCenter/CallGroups/New"
import CallCenterCallerGroupsPageNew from "../pages/CallCenter/CallerGroups/New"
import CallCenterPhoneNumbersPageItem from "../pages/CallCenter/PhoneNumbers/Item"
import CallCenterDispatchersPageItem from "../pages/CallCenter/Dispatchers/Item"
import CallCenterCallGroupsPageItem from "../pages/CallCenter/CallGroups/Item"
import CallCenterCallerGroupsPageItem from "../pages/CallCenter/CallerGroups/Item"
import AccountPermissionsPageList from "../pages/AccountPermissions/List"
import AccountPermissionsPageItem from "../pages/AccountPermissions/Item"
import AccountPermissionsPageNew from "../pages/AccountPermissions/New"
import AccountsPageList from "../pages/Accounts/List"
import AccountsPageItem from "../pages/Accounts/Item"
import AccountsPageNew from "../pages/Accounts/New"
import AccountsMenusPage from "../pages/AccountMenus/List"
import AccountsMenusPageItem from "../pages/AccountMenus/Item"
import AccountsMenusPageNew from "../pages/AccountMenus/New"
import ServiceResourcesPageList from "../pages/ServiceResources/List"
import ServiceResourcesPageItem from "../pages/ServiceResources/Item"
import ServiceResourcesPageNew from "../pages/ServiceResources/New"
import CallCenterCallFlowsPageItem from "../pages/CallCenter/CallFlows/Item"
import CallCenterCallFlowsPageNew from "../pages/CallCenter/CallFlows/New"
import ClientsPageItem from "../pages/Clients/Item"
import AppointmentsPageItem from "../pages/Appointments/Item"
import ClientsPageNew from "../pages/Clients/New"
import AppointmentCalendarePage from '../pages/AppointmentCalendar/List'
import TimeTrackingPage from '../pages/TimeTracking/List'
import CheckAvailableTrackingPage from '../pages/CheckAvailableTracker/List'
import AppointmentReschedulePage from '../pages/AppointmentReschedule/List'
import CustomerServicePage from '../pages/CustomerService/List'
import PaymentTerminalPage from '../pages/PaymentTerminal/List'
import PayoutStaffPageList from '../pages/PayoutStaff/List'
import PayoutStaffPageItem from '../pages/PayoutStaff/Item'
import PayoutStaffPageNew from '../pages/PayoutStaff/New'
import PayoutDispatcherPageList from '../pages/PayoutDispatcher/List'
import PayoutDispatcherPageItem from '../pages/PayoutDispatcher/Item'
import PayoutDispatcherPageNew from '../pages/PayoutDispatcher/New'
import StaffPageList from '../pages/Staff/List'
import StaffPageItem from '../pages/Staff/Item'
import StaffPageNew from '../pages/Staff/New'
import AdditionalTimePageList from "../pages/AdditionalTime/List"
import AdditionalTimePageItem from "../pages/AdditionalTime/Item"
import AdditionalTimePageNew from "../pages/AdditionalTime/New"
import ChatChannelsPageList from "../pages/ChatChannels/List"
import ChatChannelsPageItem from "../pages/ChatChannels/Item"
import ChatChannelsPageNew from "../pages/ChatChannels/New"
import ChatsPageList from "../pages/Chats/List"
import ChatsPageThreads from "../pages/Chats/Threads"
import ChatsPageDrafts from "../pages/Chats/Drafts"
import ChatsPageChannel from "../pages/Chats/ChannelNew"
import NewTabForGoogleAuth from "../pages/GoogleAccounts/NewTabForGoogleAuth"
import WebsitesPageList from "../pages/Websites/List"
import WebsitesPageMain from "../pages/Websites/Main"
import WebsitesPageSettings from "../pages/Websites/Settings"
import WebsitesPagePages from "../pages/Websites/Pages"
import WebsitesPageNewPage from "../pages/Websites/NewPage"
import WebsitesPageItemPage from "../pages/Websites/ItemPage"
import WebsitesPagePhoneCodes from "../pages/Websites/PhoneCodes"
import WebsitesPagePhones from "../pages/Websites/Phones"
import WebsitesPageLinks from "../pages/Websites/Links"
import WebsitesPageNew from "../pages/Websites/New"

import { useAppSelector } from "../store/hooks"

interface MainProps {
  handleCallExtension: (value: string) => void
  softphone_call_extension: boolean | undefined
  updated: number
  selectedCallCenter: string
}

export default function Main({ handleCallExtension, softphone_call_extension, updated, selectedCallCenter }: MainProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  return (
    <>
      {$router.route.name === "dashboard" &&
        <DashboardPage />}
      {$router.route.name === "ownDashboard" &&
        <DashboardPage />}
      {$router.route.name === "schedule" && <SchedulePage updated={updated} />}
      {$router.route.name === "dispatchers-schedule" && <DispatchersSchedulePage updated={updated} />}
      {$router.route.name === "google-local-service-ads" && <GoogleLocalServiceAdsPage updated={updated} />}

      {$router.route.name === "booking" && <BookingPage updated={updated} />}
      {$router.route.name === "reports" && <ReportsPage updated={updated} />}
      {$router.route.name === "mail" && <MailPage updated={updated} />}
      {$router.route.name === "mail.item" && <MailPageItem />}
      {$router.route.name === "mail.new" && <MailPageNew />}
      {$router.route.name === "tools" && <Tools updated={updated} />}

      {$router.route.name === "payment-terminal" && <PaymentTerminalPage updated={updated} />}
      {$router.route.name === "payout-staff" && <PayoutStaffPageList updated={updated} />}
      {$router.route.name === "payout-staff.item" && <PayoutStaffPageItem />}
      {$router.route.name === "payout-staff.new" && <PayoutStaffPageNew />}

      {$router.route.name === "payout-dispatcher" && <PayoutDispatcherPageList updated={updated} />}
      {$router.route.name === "payout-dispatcher.item" && <PayoutDispatcherPageItem />}
      {$router.route.name === "payout-dispatcher.new" && <PayoutDispatcherPageNew />}

      {$router.route.name === "staff" && <StaffPageList updated={updated} />}
      {$router.route.name === "staff.item" && <StaffPageItem />}
      {$router.route.name === "staff.new" && <StaffPageNew />}

      {$router.route.name === "calls" &&
        <CallsPageList
          handleCallExtension={handleCallExtension}
          softphone_call_extension={softphone_call_extension}
          updated={updated}
          call_center_id={selectedCallCenter}
        />}
      {$router.route.name === "calls.sources" && <CallsSourcesPageList />}
      {$router.route.name === "calls.numbers" && <CallsNumbersPageList />}
      {$router.route.name === "calls.areas" && <CallsAreaPageList />}

      {$router.route.name === "appointment_calendar" && <AppointmentCalendarePage updated={updated} />}
      {$router.route.name === "time-tracking" && <TimeTrackingPage updated={updated} />}
      {$router.route.name === "check-available-tracker" && <CheckAvailableTrackingPage handleCallExtension={handleCallExtension} softphone_call_extension={softphone_call_extension} updated={updated} />}

      {$router.route.name === "appointment_reschedule" && <AppointmentReschedulePage updated={updated} />}

      {$router.route.name === "customer_service" && <CustomerServicePage updated={updated} />}

      {$router.route.name === "permissions" && <PermissionsPageList updated={updated} />}
      {$router.route.name === "permissions.item" && <PermissionsPageItem />}
      {$router.route.name === "permissions.new" && <PermissionsPageNew />}

      {$router.route.name === "notify-templates" && <NotifyTemplatesPageList updated={updated} />}
      {$router.route.name === "notify-templates.item" && <NotifyTemplatesPageItem />}
      {$router.route.name === "notify-templates.new" && <NotifyTemplatesPageNew />}

      {$router.route.name === "google-auth" && <NewTabForGoogleAuth />}

      {$router.route.name === "notify-resources" && <NotifyResourcesPageList updated={updated} />}
      {$router.route.name === "notify-resources.item" && <NotifyResourcesPageItem />}
      {$router.route.name === "notify-resources.new" && <NotifyResourcesPageNew />}

      {$router.route.name === "mailboxes" && <MailboxesPageList updated={updated} />}
      {$router.route.name === "mailboxes.item" && <MailboxesPageItem />}
      {$router.route.name === "mailboxes.new" && <MailboxesPageNew />}

      {$router.route.name === "google-accounts" && <GoogleAccountsPageList updated={updated} />}
      {$router.route.name === "google-accounts.item" && <GoogleAccountsPageItem />}
      {$router.route.name === "google-accounts.new" && <GoogleAccountsPageNew />}

      {$router.route.name === "google-local-services" && <GoogleLocalServicesPageList updated={updated} />}
      {$router.route.name === "google-local-services.item" && <GoogleLocalServicesPageItem />}
      {$router.route.name === "google-local-services.new" && <GoogleLocalServicesPageNew />}

      {$router.route.name === "accounts_permissions" && <AccountPermissionsPageList updated={updated} />}
      {$router.route.name === "accounts_permissions.item" && <AccountPermissionsPageItem />}
      {$router.route.name === "accounts_permissions.new" && <AccountPermissionsPageNew />}

      {$router.route.name === "users" && <UsersPageList updated={updated} />}
      {$router.route.name === "users.new" && <UsersPageNew />}
      {$router.route.name === "users.item" && <UsersPageItem />}

      {$router.route.name === "users_menus" && <UsersMenusPageList updated={updated} />}
      {$router.route.name === "users_menus.new" && <UsersMenusPageNew />}
      {$router.route.name === "users_menus.item" && <UsersMenusPageItem />}

      {$router.route.name === "accounts" && <AccountsPageList updated={updated} />}
      {$router.route.name === "accounts.item" && <AccountsPageItem />}
      {$router.route.name === "accounts.new" && <AccountsPageNew />}

      {$router.route.name === "websites" && <WebsitesPageList updated={updated} />}
      {$router.route.name === "websites.main" && <WebsitesPageMain />}
      {$router.route.name === "websites.settings" && <WebsitesPageSettings />}
      {$router.route.name === "websites.pages" && <WebsitesPagePages />}
      {$router.route.name === "websites.new_page" && <WebsitesPageNewPage />}
      {$router.route.name === "websites.item_page" && <WebsitesPageItemPage />}
      {$router.route.name === "websites.phone_codes" && <WebsitesPagePhoneCodes />}
      {$router.route.name === "websites.phones" && <WebsitesPagePhones />}
      {$router.route.name === "websites.links" && <WebsitesPageLinks />}
      {$router.route.name === "websites.new" && <WebsitesPageNew />}

      {$router.route.name === "accounts_menus" && <AccountsMenusPage updated={updated} />}
      {$router.route.name === "accounts_menus.item" && <AccountsMenusPageItem />}
      {$router.route.name === "accounts_menus.new" && <AccountsMenusPageNew />}

      {$router.route.name === "service_resources" && <ServiceResourcesPageList updated={updated} />}
      {$router.route.name === "service_resources.item" && <ServiceResourcesPageItem />}
      {$router.route.name === "service_resources.new" && <ServiceResourcesPageNew />}

      {$router.route.name === "sms" && <SmsPage updated={updated} />}
      {$router.route.name === "sms.item" && <SmsPageItem />}
      {$router.route.name === "sms.itemMessage" && <SmsPageItemMessage />}

      {$router.route.name === "clients" && <ClientsPageList updated={updated} />}
      {$router.route.name === "clients.item" && <ClientsPageItem />}
      {$router.route.name === "clients.new" && <ClientsPageNew />}

      {$router.route.name === "conversions" && <ConversionsPageList updated={updated} />}

      {$router.route.name === "appointments" && <AppointmentsPageList updated={updated} />}
      {$router.route.name === "appointments.item" && <AppointmentsPageItem />}

      {$router.route.name === "jobs" && <JobsPageList updated={updated} />}
      {$router.route.name === "jobs.item" && <JobsPageItem />}

      {$router.route.name === "website_requests" && <WebsiteRequestPageList updated={updated} />}
      {$router.route.name === "website_requests.item" && <WebsiteRequestPageItem handleCallExtension={handleCallExtension} softphone_call_extension={softphone_call_extension} />}

      {$router.route.name === "technician_job" && <JobsPageItemTech />}
      {$router.route.name === "technician_absence" && <AbsencePageItemTech />}


      {$router.route.name === "estimates" && <EstimatesPage updated={updated} />}
      {$router.route.name === "estimates.item" && <EstimatesPageItem />}

      {$router.route.name === "deposits" && <DepositsPage updated={updated} />}
      {$router.route.name === "deposits.item" && <DepositsPageItem />}
      {$router.route.name === "deposits.new" && <DepositsPageNew />}

      {$router.route.name === "invoices" && <InvoicesPage updated={updated} />}
      {$router.route.name === "invoices.item" && <InvoicePageItem />}

      {$router.route.name === "payments" && <PaymentsPage updated={updated} />}
      {$router.route.name === "payments.item" && <PaymentsPageItem />}

      {$router.route.name === "payouts" && <PayoutsPage updated={updated} />}
      {$router.route.name === "payouts.item" && <PayoutsPageItem />}
      {$router.route.name === "payouts.new" && <PayoutsPageNew />}

      {$router.route.name === "absences" && <AbsencesPageList updated={updated} />}
      {$router.route.name === "absences.item" && <AbsencesPageItem />}
      {$router.route.name === "absences.new" && <AbsencesPageNew />}

      {$router.route.name === "tickets" && <TicketsPageList updated={updated} />}
      {$router.route.name === "tickets.item" && <TicketsPageItem />}
      {$router.route.name === "tickets.new" && <TicketsPageNew />}

      {$router.route.name === "support.faq" && <SupportPageFaq />}
      {$router.route.name === "support.tickets" && <SupportPageTickets />}
      {$router.route.name === "support.contacts" && <SupportPageContacts />}

      {$router.route.name === "lists" && activeAccountId && $router.router.navigate('lists.appliances', {
        companyId: activeAccountId
      }, { reload: true }) && null}
      {$router.route.name === "lists.appliances" && <ListsPageAppliances updated={updated} />}
      {$router.route.name === "lists.brands" && <ListsPageBrands />}
      {$router.route.name === "lists.sources" && <ListsPageSources />}
      {$router.route.name === "lists.areas" && <ListsPageAreas />}
      {$router.route.name === "lists.payments_sevices" && <ListsPagePaymentsSevices />}
      {$router.route.name === "lists.companies" && <ListsPageCompanies />}
      {$router.route.name === "lists.campaigns" && <ListsPageCampaigns />}
      {$router.route.name === "lists.warehouses" && <ListsPageWarehouses />}
      {$router.route.name === "lists.spam_filters" && <ListsPageSpamFilters />}
      {$router.route.name === "lists.ticket_templates" && <ListsPageTicketTemplates />}

      {$router.route.name === "user_groups" && <UserGroupsPageList updated={updated} />}
      {$router.route.name === "user_groups.item" && <UserGroupsPageItem />}
      {$router.route.name === "user_groups.new" && <UserGroupsPageNew />}

      {$router.route.name === "additional-time" && <AdditionalTimePageList updated={updated} />}
      {$router.route.name === "additional-time.item" && <AdditionalTimePageItem />}
      {$router.route.name === "additional-time.new" && <AdditionalTimePageNew />}

      {$router.route.name === "chat-channels" && <ChatChannelsPageList updated={updated} />}
      {$router.route.name === "chat-channels.item" && <ChatChannelsPageItem updated={updated} />}
      {$router.route.name === "chat-channels.new" && <ChatChannelsPageNew />}

      {$router.route.name === "call_center.phoneNumbers" && <CallCenterPhoneNumbersPageList updated={updated} />}
      {$router.route.name === "call_center.phoneNumbers.new" && <CallCenterPhoneNumbersPageNew />}
      {$router.route.name === "call_center.phoneNumbers.item" && <CallCenterPhoneNumbersPageItem />}

      {$router.route.name === "call_center.dispatchers" && <CallCenterDispatchersPageList />}
      {$router.route.name === "call_center.dispatchers.new" && <CallCenterDispatchersPageNew />}
      {$router.route.name === "call_center.dispatchers.item" && <CallCenterDispatchersPageItem />}

      {$router.route.name === "call_center.additionalTime" && <CallCenterAdditionalTimePageList />}
      {$router.route.name === "call_center.additionalTime.new" && <CallCenterAdditionalTimePageNew />}
      {$router.route.name === "call_center.additionalTime.item" && <CallCenterAdditionalTimePageItem />}

      {$router.route.name === "call_center.absence" && <CallCenterAbsencePageList />}
      {$router.route.name === "call_center.absence.new" && <CallCenterAbsencePageNew />}
      {$router.route.name === "call_center.absence.item" && <CallCenterAbsencePageItem />}

      {$router.route.name === "call_center.groups" && <CallCenterCallGroupsPageList />}
      {$router.route.name === "call_center.groups.new" && <CallCenterCallGroupsPageNew />}
      {$router.route.name === "call_center.groups.item" && <CallCenterCallGroupsPageItem />}

      {$router.route.name === "call_center.callFlows" && <CallCenterCallFlowsPageList />}
      {$router.route.name === "call_center.callFlows.item" && <CallCenterCallFlowsPageItem />}
      {$router.route.name === "call_center.callFlows.new" && <CallCenterCallFlowsPageNew />}

      {$router.route.name === "call_center.caller_groups" && <CallCenterCallerGroupsPageList />}
      {$router.route.name === "call_center.caller_groups.new" && <CallCenterCallerGroupsPageNew />}
      {$router.route.name === "call_center.caller_groups.item" && <CallCenterCallerGroupsPageItem />}

      {$router.route.name === "call_center.temporaryClosed" && <CallCenterTemporaryClosedPageList />}
      {$router.route.name === "call_center.temporaryClosed.new" && <CallCenterTemporaryClosedPageNew />}
      {$router.route.name === "call_center.temporaryClosed.item" && <CallCenterTemporaryClosedPageItem />}

      {$router.route.name === "call_center.callCenters" && <CallCenterCallCentersPageList />}
      {$router.route.name === "call_center.callCenters.new" && <CallCenterCallCentersPageNew />}
      {$router.route.name === "call_center.callCenters.item" && <CallCenterCallCentersPageItem />}


      {$router.route.name === "call_center.audioLibrary" && <CallCenterAudioLibraryPageList />}
      {$router.route.name === "call_center.audioLibrary.new" && <CallCenterAudioLibraryPageNew />}
      {$router.route.name === "call_center.audioLibrary.item" && <CallCenterAudioLibraryPageItem />}

      {$router.route.name === "chat" && <ChatsPageList />}
      {$router.route.name === "chat.threads" && <ChatsPageThreads />}
      {$router.route.name === "chat.drafts" && <ChatsPageDrafts />}
      {$router.route.name === "chat.item" && <ChatsPageChannel updated={updated} />}
    </>
  )
}
