import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import classNames from "classnames"
import { useLoadScript } from '@react-google-maps/api'
import moment from 'moment'

import Checkbox from './Checkbox'
import Icon from '../../components/Icon'
import SelectComponent from './Select'
import ReportFilters from './ReportFilters'
import ViewMap from './ViewMap'

import { httpClientUpdate, useOuterClick } from '../../funcs'
import { useAppSelector } from '../../store/hooks'
import { getDateRangeByPreset } from '../../funcs/reports'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { DateRangePreset } from '../../models/Misc'

interface JobMapProps {
  activeAccountId: string
}

interface HttpData {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    edit: EditProps
    jobs: JobsProps[]
    interface: ReportInterfaceProps
  }
}

interface ReportInterfaceProps {
  max_date: Date
  min_date: Date
}

interface JobsProps {
  address: string
  area: string
  created_at: string
  job_id: string
  service_resources: {
    service_resource_id: string
    nickname: string
    code: number
  }[]
  source: string
  status: string
}

interface EditProps {
  areas: {
    area_id: string
    name: string
  }[]
  service_resources: {
    active: boolean
    area: string
    area_id: string
    service_resource_id: string
    nickname: string
    code: number
    zip_codes: string[]
  }[]
  sources: {
    list_sources_id: string
    source: string
  }[]
  statuses: {
    id: string
    name: string
  }[]
}

interface SettingsProps {
  area_id: string[]
  status_id: string[]
  service_resource_id: string[]
  source_id: string[]
}

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number
}

interface addressCodesViewProps {
  address: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number,
  color: string,
  count: number
}

const libraries: (
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization"
)[] = ["places"];

const initialBlock = {
  areas: false,
  service_resources: false,
  sources: false,
  statuses: false,
  show_areas: false,
}

export default function JobMap({ activeAccountId }: JobMapProps) {
  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY as string,
    mapIds: ['ec4fc7638d3b957e'],
    libraries,
  });

  const phoneCall = useAppSelector((store) => store.phoneCall)
  const navActive = useAppSelector((store) => store.navActive)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [loading, setLoading] = useState(false)

  const [reportInterface, setReportInterface] = useState<ReportInterfaceProps | null>(null)

  const [localInterface, setLocalInterface] = useState(
    {
      search: '',

      jobSharePopup: false,
      dateRangeType: "created" as "created" | "schedule",

      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,

      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    })

  const [settings, setSettings] = useState<SettingsProps>({
    area_id: [],
    status_id: [],
    service_resource_id: [],
    source_id: [],
  })
  const [service_resources, setService_resources] = useState<string[]>([])

  const [edit, setEdit] = useState<EditProps | null>(null)

  const [isOpenBlock, setIsOpenBlock] = useState<{ [key: string]: boolean }>({})

  const [addressList, setAddressList] = useState<{ name: string, color: string, count: number }[]>([])

  const [zips, setZips] = useState<string[]>([])
  const [zipCodesView, setZipCodesView] = useState<zipCodesViewProps[]>([])
  const [addressCodesView, setAddressCodesView] = useState<addressCodesViewProps[]>([])

  const [selectedAreaShow, setSelectedAreaShow] = useState('')
  const [selectedService, setSelectedService] = useState('')

  useEffect(() => {
    loadList(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch date range preset
  useEffect(() => {
    if (!reportInterface) return

    if (localInterface.dateRangePreset === 'custom') return

    setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportInterface.min_date, reportInterface.max_date))
    })

    setZips([])
    setAddressList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset, localInterface.dateRangeType])

  useEffect(() => {
    if (zips.length || addressList.length) {
      getZipCodesView()
    } else {
      if (!zips.length) {
        setZipCodesView([])
      }
      if (!addressList.length) {
        setAddressCodesView([])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zips, addressList])

  async function loadList(isStart: boolean) {
    // https://2022back4.artemiudintsev.com/api/tools/job-map
    try {
      setLoading(true)
      let reqData = {

      }
      Object.keys(settings).forEach(item => {
        if (settings[item].length) {
          reqData[item] = settings[item]
        }
      })
      const { data: { data: reportData, success } } = (await httpClientUpdate.get('/tools/job-map', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          date_type: localInterface.dateRangeType,
          ...reqData,
        }
      })) as { data: HttpData }
      if (success) {
        if (!isStart) {
          setReportInterface({
            min_date: moment(reportData.interface.min_date).startOf('day').toDate(),
            max_date: moment(reportData.interface.max_date).endOf('day').toDate(),
          })

          setEdit(reportData.edit)

          setIsOpenBlock(initialBlock)
        }

        if (isStart) {
          let address: { name: string, color: string, count: number }[] = []

          reportData.jobs.forEach(job => {
            if (!address.map(item => item.name).includes(job.address)) {
              address.push({ name: job.address, color: job.status === 'Canceled' ? 'red' : '#00fa00', count: 1 })
            } else {
              address = address.map(add => {
                if (job.address === add.name) {
                  return {
                    ...add,
                    count: add.count + 1
                  }
                } else {
                  return { ...add }
                }
              })
            }
          })

          let zipArr: string[] = []
          reportData.edit.service_resources.forEach(item => {
            if (service_resources.includes(item.service_resource_id)) {
              item.zip_codes.forEach(zip => {
                if (!zipArr.includes(zip)) {
                  zipArr.push(zip)
                }
              })
            }
          })

          setAddressList(address)
          setZips(zipArr)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
    }
  }

  function handleStart() {
    loadList(true)
  }

  function handleToggleBlock(event: any, name: string) {
    event.stopPropagation()
    event.preventDefault()

    setIsOpenBlock({
      ...initialBlock,
      [name]: !isOpenBlock[name]
    })
  }

  function handleCloseBlock() {
    setIsOpenBlock({
      areas: false,
      service_resources: false,
      sources: false,
      statuses: false,
      show_areas: false,
    })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setIsOpenBlock({
      areas: false,
      service_resources: false,
      sources: false,
      statuses: false,
      show_areas: false,
    })
  });

  function handleChangeCheckbox(name: string, id: string) {
    const updatedSettings: SettingsProps = { ...settings }

    const checkboxes = updatedSettings[name].map((item: string) => item)

    if (checkboxes.includes(id)) {
      setSettings({
        ...updatedSettings,
        [name]: checkboxes.filter((item: string) => item !== id)
      })
    } else {
      checkboxes.push(id)

      setSettings({
        ...updatedSettings,
        [name]: checkboxes
      })
    }
  }

  function handleChangeServiceResources(id: string) {
    const updated = service_resources.map(item => item)

    if (updated.includes(id)) {
      setService_resources(
        updated.filter((item: string) => item !== id)
      )
    } else {
      updated.push(id)

      setService_resources(updated)
    }

    setZips([])
    setAddressList([])
  }

  function getServiceResource(id: string) {
    let name = ''
    let color = ''

    edit && edit.service_resources.forEach(item => {
      if (item.service_resource_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
        if (item.active) {
          color = 'inherit'
        } else {
          color = '#FF0000'
        }
      }
    })

    return { name, color }
  }

  function getZipCodesView() {
    const geocoder = new google.maps.Geocoder()
    let count = 0
    let countAddress = 0

    let selectedPlace: zipCodesViewProps[] = []
    let selectedAddress: addressCodesViewProps[] = []

    for (const address of addressList) {
      geocoder.geocode({
        'componentRestrictions': { locality: address.name, country: 'USA' }
        // eslint-disable-next-line no-loop-func
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            countAddress = countAddress + 1
            selectedAddress.push({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              address: address.name,
              isHave: true,
              place_id: results[0].place_id,
              color: address.color,
              count: address.count,
            })
          } else {
            countAddress = countAddress + 1
            selectedAddress.push({
              lat: 0,
              lng: 0,
              address: address.name,
              isHave: false,
              place_id: '',
              color: address.color,
              count: address.count,
            })
          }
        } else {
          countAddress = countAddress + 1
          selectedAddress.push({
            lat: 0,
            lng: 0,
            address: address.name,
            isHave: false,
            place_id: '',
            color: address.color,
            count: address.count,
          })
        }

        if (countAddress === addressList.length) {
          setAddressCodesView(selectedAddress)
        }
      })
    }

    for (const zipCode of zips) {
      geocoder.geocode({
        'componentRestrictions': { postalCode: zipCode, country: 'USA' }
        // eslint-disable-next-line no-loop-func
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            count = count + 1
            selectedPlace.push({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              zip_code: zipCode,
              isHave: true,
              place_id: results[0].place_id,
            })
          } else {
            count = count + 1
            selectedPlace.push({
              lat: 0,
              lng: 0,
              zip_code: zipCode,
              isHave: false,
              place_id: '',
            })
          }
        } else {
          count = count + 1
          selectedPlace.push({
            lat: 0,
            lng: 0,
            zip_code: zipCode,
            isHave: false,
            place_id: '',
          })
        }

        if (count === zips.length) {
          setZipCodesView(selectedPlace)
        }
      })
    }
  }

  function handleUpdate() {

  }

  function getSumCount() {
    let number = 0

    addressList.forEach(item => {
      number = number + item.count
    })

    return number
  }

  return (
    <>
      {
        edit &&
        <div
          onClick={handleCloseBlock}
          className="tools-job"
          ref={contextMenuRef}
        >
          <div className='tools-job-item'>
            <div className='job-main'>
              <span className='title'>Count: {getSumCount()} </span>

              <button
                className="_bordered _green"
                disabled={loading}
                onClick={handleStart}
              >
                Start
              </button>
            </div>

            <div
              className='block'
              onClick={(event) => handleToggleBlock(event, 'show_areas')}
            >
              <div className='block-name'>
                <div style={{ display: 'flex' }} >
                  <button
                    className="_zeroed _iconed"
                  >
                    <Icon
                      style={{ fill: '#8391AD' }}
                      icon="arrow-65"
                      className={classNames(
                        {
                          "_rotated-180": isOpenBlock['show_areas']
                        }
                      )}
                    />
                  </button>
                </div>

                <span>Show Areas</span>
              </div>

              {
                isOpenBlock['show_areas'] &&
                <div
                  className='block-items'
                  style={{ margin: '10px 0' }}
                >
                  <SelectComponent
                    options={edit.areas.map((area) => ({
                      span: area.name,
                      value: area.name
                    }))}
                    selectedOption={selectedAreaShow as string}
                    onChange={(value) => setSelectedAreaShow(value as string)}
                  />
                </div>
              }

              {
                isOpenBlock['show_areas'] ?
                  <div
                    className='block-items'
                    style={{ marginTop: '10px' }}
                  >
                    {
                      edit.service_resources
                        .sort((a, b) => a.area !== selectedAreaShow ? 1 : -1)
                        .sort((a, b) => service_resources.includes(a.service_resource_id) < service_resources.includes(b.service_resource_id) ? 1 : service_resources.includes(a.service_resource_id) > service_resources.includes(b.service_resource_id) ? -1 : 0)
                        .map((item, i) => (
                          <div
                            key={i}
                            className='block-item'
                            onClick={(event) => {
                              event.stopPropagation()
                              event.preventDefault()
                              handleChangeServiceResources(item.service_resource_id)
                            }}
                          >
                            <Checkbox
                              contents=""
                              value={service_resources.includes(item.service_resource_id)}
                              onChange={() => handleChangeServiceResources(item.service_resource_id)}
                            />
                            <span style={{ color: item.active ? 'inherit' : '#FF0000' }}>
                              {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                            </span>
                          </div>
                        ))
                    }
                  </div>
                  :
                  <div
                    className='block-items'
                    style={{ marginTop: service_resources.length ? '10px' : 0 }}
                  >
                    {
                      service_resources.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeServiceResources(item)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={service_resources.includes(item)}
                            onChange={() => handleChangeServiceResources(item)}
                          />
                          <span style={{ color: getServiceResource(item).color }}>
                            {getServiceResource(item).name}
                          </span>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>

            <div
              className='block'
              onClick={(event) => handleToggleBlock(event, 'statuses')}
            >
              <div className='block-name'>
                <div style={{ display: 'flex' }} >
                  <button
                    className="_zeroed _iconed"
                  >
                    <Icon
                      style={{ fill: '#8391AD' }}
                      icon="arrow-65"
                      className={classNames(
                        {
                          "_rotated-180": isOpenBlock['statuses']
                        }
                      )}
                    />
                  </button>
                </div>

                <span>Status</span>
              </div>

              {
                isOpenBlock['statuses'] ?
                  <div
                    className='block-items'
                    style={{ marginTop: '10px' }}
                  >
                    {
                      edit.statuses.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('status_id', item.id)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.status_id.includes(item.id)}
                            onChange={() => handleChangeCheckbox('status_id', item.id)}
                          />
                          <span>
                            {item.name}
                          </span>
                        </div>
                      ))
                    }
                  </div> :
                  <div
                    className='block-items'
                    style={{ marginTop: settings.status_id.length ? '10px' : 0 }}
                  >
                    {
                      settings.status_id.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('status_id', item)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.status_id.includes(item)}
                            onChange={() => handleChangeCheckbox('status_id', item)}
                          />
                          <span>
                            {edit.statuses.filter(status => status.id === item).length ? edit.statuses.filter(status => status.id === item)[0].name : ''}
                          </span>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>

            <div
              className='block'
              onClick={(event) => handleToggleBlock(event, 'sources')}
            >
              <div className='block-name'>
                <div style={{ display: 'flex' }} >
                  <button
                    className="_zeroed _iconed"
                  >
                    <Icon
                      style={{ fill: '#8391AD' }}
                      icon="arrow-65"
                      className={classNames(
                        {
                          "_rotated-180": isOpenBlock['sources']
                        }
                      )}
                    />
                  </button>
                </div>

                <span>Source</span>
              </div>

              {
                isOpenBlock['sources'] ?
                  <div
                    className='block-items'
                    style={{ marginTop: '10px' }}
                  >
                    {
                      edit.sources.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('source_id', item.list_sources_id)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.source_id.includes(item.list_sources_id)}
                            onChange={() => handleChangeCheckbox('source_id', item.list_sources_id)}
                          />
                          <span>
                            {item.source}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div
                    className='block-items'
                    style={{ marginTop: settings.source_id.length ? '10px' : 0 }}
                  >
                    {
                      settings.source_id.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('source_id', item)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.source_id.includes(item)}
                            onChange={() => handleChangeCheckbox('source_id', item)}
                          />
                          <span>
                            {edit.sources.filter(source => source.list_sources_id === item).length ? edit.sources.filter(source => source.list_sources_id === item)[0].source : ''}
                          </span>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>

            <div
              className='block'
              onClick={(event) => handleToggleBlock(event, 'areas')}
            >
              <div className='block-name'>
                <div style={{ display: 'flex' }} >
                  <button
                    className="_zeroed _iconed"
                  >
                    <Icon
                      style={{ fill: '#8391AD' }}
                      icon="arrow-65"
                      className={classNames(
                        {
                          "_rotated-180": isOpenBlock['areas']
                        }
                      )}
                    />
                  </button>
                </div>

                <span>Areas</span>
              </div>

              {
                isOpenBlock['areas'] ?
                  <div
                    className='block-items'
                    style={{ marginTop: '10px' }}
                  >
                    {

                      edit.areas.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('area_id', item.area_id)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.area_id.includes(item.area_id)}
                            onChange={() => handleChangeCheckbox('area_id', item.area_id)}
                          />
                          <span>
                            {item.name}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div
                    className='block-items'
                    style={{ marginTop: settings.area_id.length ? '10px' : 0 }}
                  >
                    {
                      settings.area_id.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('area_id', item)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.area_id.includes(item)}
                            onChange={() => handleChangeCheckbox('area_id', item)}
                          />
                          <span>
                            {edit.areas.filter(area => area.area_id === item).length ? edit.areas.filter(area => area.area_id === item)[0].name : ''}
                          </span>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>

            <div
              className='block'
              onClick={(event) => handleToggleBlock(event, 'service_resources')}
            >
              <div className='block-name'>
                <div
                  style={{ display: 'flex' }}
                >
                  <button
                    className="_zeroed _iconed"
                  >
                    <Icon
                      style={{ fill: '#8391AD' }}
                      icon="arrow-65"
                      className={classNames(
                        {
                          "_rotated-180": isOpenBlock['service_resources']
                        }
                      )}
                    />
                  </button>
                </div>

                <span>Service Resources</span>
              </div>

              {
                isOpenBlock['service_resources'] &&
                <div
                  className='block-items'
                  style={{ margin: '10px 0' }}
                >
                  <SelectComponent
                    options={edit.areas.map((area) => ({
                      span: area.name,
                      value: area.name
                    }))}
                    selectedOption={selectedService as string}
                    onChange={(value) => setSelectedService(value as string)}
                  />
                </div>
              }

              {
                isOpenBlock['service_resources'] ?
                  <div className='block-items'>
                    {
                      edit.service_resources
                        .sort((a, b) => a.area !== selectedService ? 1 : -1)
                        .sort((a, b) => settings.service_resource_id.includes(a.service_resource_id) < settings.service_resource_id.includes(b.service_resource_id) ? 1 : settings.service_resource_id.includes(a.service_resource_id) > settings.service_resource_id.includes(b.service_resource_id) ? -1 : 0)
                        .map((item, i) => (
                          <div
                            key={i}
                            className='block-item'
                            onClick={(event) => {
                              event.stopPropagation()
                              event.preventDefault()
                              handleChangeCheckbox('service_resource_id', item.service_resource_id)
                            }}
                          >
                            <Checkbox
                              contents=""
                              value={settings.service_resource_id.includes(item.service_resource_id)}
                              onChange={() => handleChangeCheckbox('service_resource_id', item.service_resource_id)}
                            />
                            <span style={{ color: item.active ? 'inherit' : '#FF0000' }}>
                              {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                            </span>
                          </div>
                        ))
                    }
                  </div>
                  :
                  <div
                    className='block-items view_item'
                    style={{ marginTop: settings.service_resource_id.length ? '10px' : 0 }}
                  >
                    {
                      settings.service_resource_id.map((item, i) => (
                        <div
                          key={i}
                          className='block-item'
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            handleChangeCheckbox('service_resource_id', item)
                          }}
                        >
                          <Checkbox
                            contents=""
                            value={settings.service_resource_id.includes(item)}
                            onChange={() => handleChangeCheckbox('service_resource_id', item)}
                          />
                          <span style={{ color: getServiceResource(item).color }}>
                            {getServiceResource(item).name}
                          </span>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>

          <div className='tools-job-item'>
            <ReportFilters
              searchButtonActive={false}

              dateRangeType={localInterface.dateRangeType}
              onDateRangeTypeChange={(value) => setLocalInterface({ ...localInterface, dateRangeType: value as any })}

              dateRangePreset={localInterface.dateRangePreset}
              onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value })}

              minDate={localInterface.min_date}
              onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom' })}

              maxDate={localInterface.max_date}
              onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom' })}

              updateButtonActive={false}
              onUpdate={() => handleUpdate()}

              selectSearch={true}

              phoneCall={phoneCall}
              navActive={navActive.is}
            />

            <div className='map'>
              {
                (!!zipCodesView.length || !!addressCodesView.length) &&
                <ViewMap
                  selectedZipCode={zipCodesView.filter(item => item.isHave)}
                  addressCodesView={addressCodesView.filter(item => item.isHave)}
                  loadError={loadError}
                  isLoaded={isLoaded}
                />
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
