import { memo, useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import InputMask from 'react-input-mask';

import InfoBlockDial from './InfoBlockDial'
/* eslint-disable @typescript-eslint/no-unused-vars */
import AutoCompliteSelectComponent from '../AutoCompliteSelect';
import Icon from '../Icon'

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from '../../funcs';
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';
import { CallsProps, ClientsProps, CurrentCallProps } from '../../App';

export interface CallsDataProps {
  appointments: []
  calls: CallsProps[]
  clients: ClientsProps[]
  current_call: CurrentCallProps
  permissions: {
    appointment_show: boolean
    call_call_record: boolean
    client_show: boolean
  }
}

interface HttpGetCallsInfoReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsDataProps
}

interface HttpGetAccountsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    phone_numbers: AccountsProps[]
  }
}

interface AccountsProps {
  friendly_name: string
  phone: string
  phonenumber_id: string
}

interface ContactProps {
  company_name: string
  first_name: string
  last_name: string
  phone: string
}

interface ClientProps {
  client_id: string
  company_name: string
  first_name: string
  last_name: string
}

const MaskedInput = (props: any) => (
  <InputMask
    mask='+9 (999) 999-9999'
    value={props.value}
    onChange={props.onChange}
    alwaysShowMask={false}>
    {(inputProps: any) => <input {...inputProps} />}
  </InputMask>
);

const phoneOptions = [
  {
    line: [
      {
        digit: '1',
        letters: ''
      },
      {
        digit: '4',
        letters: 'ghi'
      },
      {
        digit: '7',
        letters: 'pqrs'
      },
      {
        digit: '',
        letters: '*'
      }
    ]
  }, {
    line: [
      {
        digit: '2',
        letters: 'abc'
      },
      {
        digit: '5',
        letters: 'jkl'
      },
      {
        digit: '8',
        letters: 'tuv'
      },
      {
        digit: '0',
        letters: '+'
      }
    ]
  }, {
    line: [
      {
        digit: '3',
        letters: 'def'
      },
      {
        digit: '6',
        letters: 'mno'
      },
      {
        digit: '9',
        letters: 'wxyz'
      },
      {
        digit: '',
        letters: '#'
      }
    ]
  }
]

interface DialContentProps {
  number: string
  setNumber: (value: string) => void
  selectedNumberFromContact: Partial<ContactProps>
  handleDialCall: (to: string, phone_id: string) => void
  softphone_call_dial: boolean | undefined
}

function DialContent({
  number,
  setNumber,
  selectedNumberFromContact,
  handleDialCall,
  softphone_call_dial
}: DialContentProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [selectedAccount, setSelectedAccount] = useState({ id: '', name: '' })
  const [accountsData, setAccountsData] = useState<AccountsProps[] | []>([])
  const [callsData, setCallsData] = useState<CallsProps[] | []>([])
  const [client, setClient] = useState<ClientProps[] | []>([])


  // Get contacts
  async function getAccounts() {
    // https://2022back4.artemiudintsev.com/api/callcenter/phonenumbers/sources?account_id=88888
    try {
      const { data: { data: accountsData, success, error } } = await httpClientUpdate.get('/callcenter/phonenumbers/sources',
        {
          params: {
            account_id: activeAccountId,
          }
        }) as { data: HttpGetAccountsReport }
      if (success) {
        setAccountsData(accountsData.phone_numbers)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Get contacts
  async function getInfoByPhone() {
    // https://2022back4.artemiudintsev.com/api/callcenter/phonenumbers/sources?account_id=88888
    try {
      const { data: { data: callsData, success, error } } = await httpClientUpdate.get('/callcenter/callerinfo',
        {
          params: {
            account_id: activeAccountId,
            phone_number: number
          }
        }) as { data: HttpGetCallsInfoReport }
      if (success) {
        setClient(callsData.clients)
        setCallsData(callsData.calls)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    getAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (number.length === 12) {
      getInfoByPhone()
    }
    setSelectedAccount({ id: '', name: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number])

  function handleRemove() {
    number.length > 1 &&
      setNumber(number.slice(0, -1))
  }

  function handleChangeNumber(num: string) {
    if (number === '+' && num !== '1') {
      setNumber(`${number}1${num}`)
    } else {
      setNumber(`${number}${num}`)
    }
  }

  function handleChangeInputNumber(num: string) {
    let numberFormat = formatPhoneNumberToServerString(num)

    if (numberFormat[1] && numberFormat[1] !== '1') {
      setNumber(numberFormat.replace(numberFormat[1], `1${numberFormat[1]}`))
    } else {
      setNumber(numberFormat)
    }
  }

  function checkCall() {
    let check = false

    if (selectedAccount.id === '') {
      check = true
    }

    if (number.length < 12) {
      if (callsData.length) {
        setCallsData([])
      }
      if (client.length) {
        setClient([])
      }
      check = true
    }
    return check
  }

  function getName(company: string, firstName: string, lastName: string) {
    let stringName = ''
    if (!firstName && !lastName && company) {
      stringName = company
    } else if (!company) {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    } else if (firstName && lastName && company) {
      stringName = `${firstName} ${lastName} (${company})`
    } else if (!firstName && !lastName && !company) {
      stringName = 'Unknown'
    } else {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''} (${company})`
    }

    return stringName
  }

  return (
    <div className='section__account_softphone-content_item section__account_softphone-content_dial active'>
      <div className='section__account_softphone-content_dial-block'>
        <div className='section__account_softphone-content_dial-input'>
          <div className='section__account_softphone-content_dial-backspace'>
            <div
              onClick={() => handleRemove()}
              className='section__account_softphone-content_dial-backspaceIcon'
            >
              <Icon viewBox="0 0 24 18" style={{ fill: '#8E8E93' }} icon="backspace-1" />
            </div>
          </div>

          <MaskedInput
            value={number}
            onChange={(event: any) => handleChangeInputNumber(event.target.value)}
          >
          </MaskedInput>
        </div>

        {
          (!!Object.keys(selectedNumberFromContact).length || !!client.length) &&
          <div className='section__account_softphone-content_dial-contact'>
            <div className='section__account_softphone-content_dial-contIcon'>
              <Icon viewBox="0 0 14 16" style={{ fill: '#8E8E93' }} icon="account-2" />
            </div>

            <p className='section__account_softphone-content_dial-contName'>
              {
                !!Object.keys(selectedNumberFromContact).length ?
                  `${selectedNumberFromContact.first_name} ${selectedNumberFromContact.last_name}` :
                  getName(client[0].company_name, client[0].first_name, client[0].last_name)
              }

            </p>
          </div>
        }

        <div className='section__account_softphone-content_dial-account'>
          <div className='section__account_softphone-content_dial-accBlock'>
            <p className='section__account_softphone-content_dial-accFrom'>From:</p>

            <AutoCompliteSelectComponent
              selectedOption={selectedAccount.id}
              is_dial={true}
              options={accountsData.map((item) => ({
                span: `${item.friendly_name}  (${item.phone})`,
                value: item.phonenumber_id
              }))}
              onChange={(value: string) => value && setSelectedAccount({ id: value, name: accountsData.filter(item => item.phonenumber_id === value)[0].friendly_name })}
            />
          </div>
        </div>

        <div className='section__account_softphone-content_dial-keyboard'>
          <div className='section__account_softphone-content_dial-keys'>
            {
              phoneOptions.map((item, index) => (
                <div key={index} className='section__account_softphone-content_dial-keyLine'>
                  {
                    item.line.map((line, idx) => (
                      <div
                        onClick={() => number.length < 12 && handleChangeNumber(line.digit)}
                        key={idx}
                        className='section__account_softphone-content_dial-key'
                      >
                        <p className='section__account_softphone-content_dial-digit'>
                          {line.digit}
                        </p>

                        <p style={{ fontSize: line.digit ? '14px' : '22px' }} className='section__account_softphone-content_dial-letters'>
                          {line.letters}
                        </p>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>

          <div className='section__account_softphone-content_dial-phoneCall'>
            <div
              className='section__account_softphone-content_dial-phoneCallIcon'
              onClick={() => softphone_call_dial && !checkCall() && handleDialCall(number, selectedAccount.id)}
              style={{
                opacity: !softphone_call_dial && checkCall() ? '.4' : '1',
                cursor: !softphone_call_dial && checkCall() ? 'inherit' : 'pointer'
              }}
            >
              <Icon viewBox="0 0 16 17" style={{ fill: 'white' }} icon="call-3" />
            </div>
          </div>
        </div>

        <InfoBlockDial
          calls={callsData}
          client_name={client.length ? getName(client[0].company_name, client[0].first_name, client[0].last_name) : ''}
          client_show={true}
          client_id={client.length ? client[0].client_id : ''}
        />
      </div>
    </div>
  )
}

export default memo(DialContent)
