import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useRoute } from "react-router5"
import { Device } from 'twilio-client'
import classNames from "classnames"
import qs from "qs"
import { Transition } from 'react-transition-group'

import Icon from "./components/Icon"
import AuthPage from "./pages/Auth"
import HelpMenu from "./components/HelpMenu"
import NotificationsWidget from "./components/NotificationsWidget"
import Page404 from "./pages/Page404"
import Page500 from "./pages/Page500"
import Page403 from "./pages/Page403"
import Page401 from "./pages/Page401"
import Header from "./components/Header";
import AsideNavPanel from "./components/AsideNavPanel"
import Main from "./components/Main"
import OnlineCall from "./components/OnlineCall/OnlineCall";
import Audio from "./components/Audio";

import { httpClientUpdate, nErrorUpdate } from "./funcs";
import { useAppSelector, useAppDispatch } from "./store/hooks"
import {
  setNavActive,
  setUser,
  setMenus,
  setRedirectRoute,
  setAccounts,
  setActiveAccountId,
  setPhoneCall,
} from "./store/reducer"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import User from "./models/User"
import Account from "./models/Account";

interface HttpTokenReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    token: string
    permissions: PermissionsProps
    dispatchers: DispatchersProps[]
  }
}

interface HttpCallerReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsDataProps
}

export interface DispatchersProps {
  code: number
  dispatcher_id: string
  nickname: string
}

export interface PermissionsProps {
  appointment_show: boolean
  client_show: boolean
  softphone_add_comment: boolean
  softphone_make_appointment: boolean
  softphone_make_ticket: boolean
  softphone_mark_blacklist: boolean
  softphone_reschedule_appointment: boolean

  softphone_call_dial: boolean
  call_call_report: boolean
  softphone_call_extension: boolean
  softphone_calls: boolean
  softphone_phonebook: boolean
}

export interface CallsDataProps {
  appointments: []
  calls: CallsProps[]
  clients: ClientsProps[]
  current_call: CurrentCallProps
  permissions: {
    appointment_show: boolean
    call_call_record: boolean
    client_show: boolean
  }
}

export interface ClientsProps {
  client_id: string
  first_name: string
  last_name: string
  company_name: string
}

export interface CallsProps {
  answered_dispatcher_id: string
  call_id: string
  created_at: string
  direction: string
  dispatcher: string
  dispatcher_code: number
  friendly_name: string
  status: string
}

export interface CurrentCallProps {
  to_phone: string
  from_phone: string
  friendly_name: string
  area: string
  caller_name: string
  caller_city: string
  caller_state: string
  caller_zip: string
  caller_sid: string
  call_sid: string
  type: string
  status: string
  recording_url: string
  company_id: string
  company_name: string
  source_id: string
  source: string
  is_hold?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function App() {

  const $router = useRoute()
  const dispatch = useAppDispatch()

  const user = useAppSelector((store) => store.user)
  const menus = useAppSelector((store) => store.menus)
  const channels = useAppSelector((store) => store.channels)
  const navMode = useAppSelector((store) => store.navMode)
  const navActive = useAppSelector((store) => store.navActive)
  const accessToken = useAppSelector((store) => store.accessToken)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accounts = useAppSelector((store) => store.accounts)
  const redirectRoute = useAppSelector((store) => store.redirectRoute)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const phoneCallStatus = useAppSelector((store) => store.phoneCallStatus)

  const $player = useRef<HTMLAudioElement>(null)

  const [isBell, setIsBell] = useState<number | null>(null)
  const [agreeSound, setAgreeSound] = useState(false)

  const [activeNavButton, setActiveNavButton] = useState<string | null>(null)
  const [searchPanelExpanded, setSearchPanelExpanded] = useState<boolean>(false)

  const [updated, setUpdated] = useState(0)

  const [statusNetwork, setStatusNetwork] = useState(true)

  // state for online Call
  const [token, setToken] = useState('')
  const [selectedCallCenter, setSelectedCallCenter] = useState('')
  const [device, setDevice] = useState<Device | null>(null)
  const [callsData, setCallsData] = useState<CallsDataProps[] | []>([])
  const [incomingCall, setIncomingCall] = useState<CallsDataProps[] | []>([])
  const [incomingConnection, setIncomingConnection] = useState(null)
  const [connectCall, setConnectCall] = useState({})
  const [permissions, setPermissions] = useState<Partial<PermissionsProps>>({})
  const [dispatcherTwillio, setDispatcherTwillio] = useState<DispatchersProps[]>([])

  const [notifications_allow, setNotifications_allow] = useState(false)
  const [twilio_allow, setTwilio_allow] = useState(false)

  const [reconnecting, setReconnecting] = useState(0)
  const [canceledCall, setCanceledCall] = useState('')

  const [isCloseBefore, setIsCloseBefore] = useState(false)

  const [notify, setNotify] = useState({
    today: 0,
    yesterday: 0,
    weekend: 0,
    need_confirmation: 0,
    need_check: 0,
    staff_need_confirmation: 0,
    staff_need_check: 0,
  })

  const [lastNotify, setLastNotify] = useState({
    today: 0,
    yesterday: 0,
    weekend: 0,
    need_confirmation: 0,
    need_check: 0,
    staff_need_confirmation: 0,
    staff_need_check: 0,
  })

  useEffect(() => {
    if (isBell === null) return

    if (isBell) {
      $player.current?.play()
    } else {
      $player.current?.pause()
    }

  }, [isBell])

  function handleBell() {
    $player.current?.pause()
    setAgreeSound(!agreeSound)
  }

  function togglePhoneCall() {
    dispatch(setPhoneCall(!phoneCall))
    setActiveNavButton(null)
  }

  // Is page is permitted to access function
  const isPageAllowed = (page: string) => {

    if (!menus) return null

    let pages = []

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [key, value] of Object.entries(menus)) {

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (let [_key, _value] of Object.entries(value)) {

        pages.push((_value as any).name)
      }
    }

    return pages.includes(page.split('.')[0])
  }

  // const isNavItemAllowed = (page: string) =>

  function isNavItemAllowed(page: string) {
    if (navMode !== 'channels') {
      return menus ? menus[navMode].findIndex((item) => item.name === page.split('.')[0]) !== -1 : null
    } else {
      return null
    }
  }

  function onNavSwitchNotchHover() {
    dispatch(setNavActive({
      floating: true,
      is: true
    }))
  }

  function onNavSwitchNotchLeft() {
    if (!navActive.floating) return;
    dispatch(
      setNavActive({
        floating: true,
        is: false
      })
    )

    setTimeout(() => {
      dispatch(
        setNavActive({
          floating: false,
          is: false
        })
      )
    }, 210)
  }

  // Redirect if no auth function
  function redirectIfNoAuth() {
    if (
      ![
        'auth'
      ].includes($router.route.name) &&
      !accessToken
    ) {
      $router.router.navigate('auth')
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(redirectIfNoAuth, [])

  useEffect(() => {
    if (isCloseBefore) {
      dispatch(setNavActive({ ...navActive, is: false }))
      setIsCloseBefore(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.route.path])

  useEffect(() => {
    window.addEventListener('online', () => setStatusNetwork(true))
    window.addEventListener('offline', () => setStatusNetwork(false))
  }, [])

  // Handle account changing
  useEffect(() => {
    (async () => {
      if (activeAccountId === null) return

      let $user: User

      try {
        // Get user info
        let { data: { data: { user, permissions }, success, error } } = await httpClientUpdate.post('/users/profile', qs.stringify({ account_id: activeAccountId }), {
          headers: {
            'Accept': 'application/jsons',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${accessToken}`
          }
        })

        if (!success) {
          $router.router.navigate(`${error.code}`, {
            reload: true
          })
        }
        $user = user[0]
        setNotifications_allow(permissions.notifications_allow)
        setTwilio_allow(permissions.twilio_use)
        setPermissions({
          softphone_calls: permissions.softphone_calls
        })

        if (user[0].call_centers.length > 1) {
          setSelectedCallCenter(user[0].call_centers[0].call_center_id)
        }

        // Get user menus
        let { data: { data: menus, success: successMenus, error: errorMenus } } = await httpClientUpdate.get(`/menus/${user[0].user_id}`, {
          headers: {
            'Accept': 'application/jsons',
            'Authorization': `Bearer ${accessToken}`
          }
        })

        if (!successMenus) {
          $router.router.navigate(`${errorMenus.code}`, {
            reload: true
          })
        }

        // Get accounts
        let { data: { data: { accounts }, success: successAccounts, error: errorAccounts } } = await httpClientUpdate.get('/accounts', {
          headers: {
            'Accept': 'application/jsons',
            'Authorization': `Bearer ${accessToken}`
          }
        })

        if (!successAccounts) {
          $router.router.navigate(`${errorAccounts.code}`, {
            reload: true
          })
        }

        dispatch(
          setAccounts(accounts)
        )

        dispatch(
          setUser($user)
        );

        dispatch(
          setMenus(menus)
        );

        // Redirect to dashboard
        if (redirectRoute) {
          $router.router.navigate(redirectRoute.name, redirectRoute.params, { reload: true });
        } else {
          if ($router.route.name !== "auth") {
            if (accounts.filter((item: Account) => item.account_id === $router.router.getState().params.companyId).length
            ) {
              if ($router.router.getState().params.companyId !== activeAccountId) {
                dispatch(setActiveAccountId($router.router.getState().params.companyId))
              }
            } else {
              if (
                !['auth', 'dashboard', '403', '401', '404', '500', 'calls', 'clients', 'appointments',
                  'jobs', 'estimates', 'invoices', 'payments', 'absences', 'service_resources',
                  'tickets', 'support', 'lists', 'permissions', 'accounts_permissions', 'users',
                  'accounts', 'call_center'
                ].includes($router.router.getState().name)
              ) {
                if (activeAccountId) {
                  $router.router.navigate(`dashboard`, { companyId: activeAccountId }, {
                    reload: true
                  })
                } else {
                  $router.router.navigate(`403`, {
                    reload: true
                  })
                }
              } else {
                dispatch(setActiveAccountId(null))

                $router.router.navigate('auth', {
                  mode: 'account-select'
                }, { reload: true })
              }
            }
          }
        }
      } catch (error) {
        // dispatch(setActiveAccountId(null))
        $router.router.navigate(`auth`, {
          reload: true
        })
      }
    })();
    return () => {
      // Component unmount code.
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId]);

  useEffect(() => {
    if (activeAccountId !== null &&
      user &&
      $router.route?.params?.mode === 'account-select') {

      $router.router.navigate('dashboard', { companyId: activeAccountId }, { reload: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.route, activeAccountId, user])

  // Handle account select on mount
  useEffect(() => {
    if (
      accessToken &&
      !activeAccountId &&
      $router.route.name !== 'auth'
    ) {
      httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      dispatch(
        setRedirectRoute($router.router.getState())
      )

      $router.router.navigate('auth', {
        mode: 'account-select'
      }, { reload: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Handle route name changes
  useEffect(() => {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    // Close nav and other widgets on mobile devices
    if (window.innerWidth < 768) {
      dispatch(
        setNavActive({
          floating: false,
          is: false
        })
      )
      setActiveNavButton(null)
    }

    // Handle page permission
    if (!['auth', 'dashboard', '403'].includes($router.router.getState().name)) {

      if (isPageAllowed($router.router.getState().name) === false
      ) {
        if ((redirectRoute?.params.companyId &&
          accounts.map(item => item.account_id).includes(redirectRoute?.params.companyId)) ||
          accounts.map(item => item.account_id).includes($router.router.getState().params.companyId)
        ) {

        } else {
          if ($router.router.getState().name === 'ownDashboard' && activeAccountId) {
            $router.router.navigate(`dashboard`, { companyId: activeAccountId }, {
              reload: true
            })
          } else {
            // $router.router.navigate('403')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.route.name])

  useEffect(() => {
    if (window.innerWidth < 768) {
      dispatch(
        setNavActive({
          floating: false,
          is: false
        })
      )
      setActiveNavButton(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Get permissions to audio
  async function getMedia() {

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      /* используем поток */
    } catch (err) {
      /* обработка ошибки */
    }
  }

  // Get Token for twilio
  async function getToken() {
    try {
      const params: {
        account_id: string | null
        call_center_id?: string
      } = {
        account_id: activeAccountId
      }
      if (selectedCallCenter) {
        params.call_center_id = selectedCallCenter
      }

      const { data: { data: tokenData, success, error } } = await httpClientUpdate.get('/callcenter/twilio',
        {
          params: {
            ...params
          }
        }) as { data: HttpTokenReport }

      if (success) {
        let device = new Device(tokenData.token, { allowIncomingWhileBusy: true })

        setToken(tokenData.token)
        setPermissions(tokenData.permissions)
        setDevice(device)
        setDispatcherTwillio(tokenData.dispatchers)
        getMedia()
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Update info by caller
  async function updateCallerInfo(sid: string) {
    // https://2022back4.artemiudintsev.com/api/callcenter/callerinfo?account_id=88888&sid=CAbc97a5cb287b7c47d906a730b3fd5b49
    try {
      const { data: { data: callerData, success, error } } = await httpClientUpdate.get('/callcenter/callerinfo',
        {
          params: {
            account_id: activeAccountId,
            call_sid: sid
          }
        }) as { data: HttpCallerReport }
      if (success) {
        return callerData
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Get info by caller
  async function getCallerInfo(sid: string) {
    // https://2022back4.artemiudintsev.com/api/callcenter/callerinfo?account_id=88888&sid=CAbc97a5cb287b7c47d906a730b3fd5b49
    try {
      const { data: { data: callerData, success, error } } = await httpClientUpdate.get('/callcenter/callerinfo',
        {
          params: {
            account_id: activeAccountId,
            call_sid: sid
          }
        }) as { data: HttpCallerReport }
      if (success) {
        let updateCalls = incomingCall.map(item => item)
        updateCalls.push({
          ...callerData,
          current_call: {
            ...callerData.current_call,
            caller_sid: sid,
            type: 'incoming',
            status: 'Incoming Call'
          }
        })

        setIncomingCall(updateCalls)
        if (!phoneCall) {
          dispatch(setPhoneCall(true))
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Get info by caller
  async function getOutgoingInfo(sid: string) {
    // https://2022back4.artemiudintsev.com/api/callcenter/callerinfo?account_id=88888&sid=CAbc97a5cb287b7c47d906a730b3fd5b49
    try {
      const { data: { data: callerData, success, error } } = await httpClientUpdate.get('/callcenter/callerinfo',
        {
          params: {
            account_id: activeAccountId,
            call_sid: sid
          }
        }) as { data: HttpCallerReport }
      if (success) {
        let updateCalls = incomingCall.map(item => item)
        updateCalls.push({
          ...callerData,
          current_call: {
            ...callerData.current_call,
            caller_sid: sid,
            type: 'outgoing',
            status: 'Outgoing Call'
          }
        })

        setIncomingCall(updateCalls)
        if (!phoneCall) {
          dispatch(setPhoneCall(true))
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // add comment
  async function handleAddComment(call_sid: string, tag: string, comment: string) {
    // https://2022back4.artemiudintsev.com/api/callcenter/comments
    const response = await httpClientUpdate.post(`/callcenter/comments`, qs.stringify({
      account_id: activeAccountId,
      call_sid,
      tag: tag.replace(/,/g, " ").split(' ').filter(item => item && item.trim()).join(', '),
      comment,
    }))
    if (response.data.success) {
      // $router.router.navigate('jobs', {
      //   companyId: activeAccountId,
      // }, { reload: true })
    }
  }

  // redirect call
  async function handleRedirectCall(call_sid: string, dispatcher_id: string) {
    // https://2022back4.artemiudintsev.com/api/calls/twilio/transfer
    const response = await httpClientUpdate.post(`/calls/twilio/transfer`, qs.stringify({
      account_id: activeAccountId,
      call_sid,
      dispatcher_id: dispatcher_id,
    }))
    if (response.data.success) {
      // $router.router.navigate('jobs', {
      //   companyId: activeAccountId,
      // }, { reload: true })
    }
  }


  // pause call
  async function handlePauseCall(call_sid: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/calls/twilio/hold
      const response = await httpClientUpdate.post(`/calls/twilio/hold`, qs.stringify({
        account_id: activeAccountId,
        call_sid,
        call_center_id: selectedCallCenter,
      }))

      if (response.data.success) {
        let updatedCall = callsData.map(item => {
          return {
            ...item,
            current_call: {
              ...item.current_call,
              is_hold: true,
            }
          }
        })
        setCallsData(updatedCall)
      }
    } catch (error) { }
  }

  // resume call
  async function handleResumeCall(call_sid: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/calls/twilio/resume
      const response = await httpClientUpdate.post(`/calls/twilio/resume`, qs.stringify({
        account_id: activeAccountId,
        call_sid,
        call_center_id: selectedCallCenter,
      }))
      if (response.data.success) {
        let updatedCall = callsData.map(item => {
          return {
            ...item,
            current_call: {
              ...item.current_call,
              is_hold: false,
            }
          }
        })
        setCallsData(updatedCall)
      }
    } catch (error) { }
  }

  useEffect(() => {
    updated && setTimeout(() => {
      setUpdated(0)
    }, 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    if (phoneCallStatus !== 'Not Available') {
      getToken()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCallStatus, activeAccountId, reconnecting])

  useEffect(() => {
    if (incomingCall.length) {
      if (!phoneCall) {
        dispatch(setPhoneCall(true))
      }

      let updateCall = callsData.map(item => item)
      updateCall.push(incomingCall[0])
      setCallsData(updateCall)
      incomingConnection && setConnectCall({ ...connectCall, [incomingCall[0].current_call.caller_sid]: incomingConnection })
      setIncomingCall([])
      setIncomingConnection(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingCall])

  useEffect(() => {
    if (canceledCall) {
      let updateConnectCall = { ...connectCall }
      delete updateConnectCall[canceledCall]

      let checkActiveCalls = callsData.filter(item => item.current_call.status === 'active' && item.current_call.caller_sid === canceledCall)

      if (checkActiveCalls.length) {
        setTimeout(async () => {
          let getUpdateCaller = await updateCallerInfo(canceledCall)

          let updateCalls = callsData.map(item => {
            if (item.current_call.caller_sid === canceledCall && getUpdateCaller && getUpdateCaller.current_call) {
              return {
                ...getUpdateCaller,
                current_call: {
                  ...getUpdateCaller.current_call,
                  caller_sid: canceledCall,
                  status: 'completed'
                }
              }
            } else {
              return item
            }
          })
          setCallsData(updateCalls)
        }, 100)

        let updateCalls = callsData.map(item => {
          if (item.current_call.caller_sid === canceledCall) {
            return {
              ...item,
              current_call: {
                ...item.current_call,
                status: 'completed'
              }
            }
          } else {
            return item
          }
        })
        setCallsData(updateCalls)
      } else {
        let updateCalls = callsData.filter(item => (item.current_call.status === 'completed') || (item.current_call.status === 'Incoming Call' && item.current_call.caller_sid !== canceledCall))
        setCallsData(updateCalls)
      }
      setConnectCall(updateConnectCall)
      setCanceledCall('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canceledCall, callsData])

  useEffect(() => {
    if (token && device && phoneCallStatus === 'Available') {

      // ready 
      device.on('ready', device => {
        device.audio.availableOutputDevices.forEach((device: any, id: string) => {
        });
      });

      device.on("connect", function (conn) {
        if (conn.direction === 'OUTGOING') {
          getOutgoingInfo(conn.parameters.CallSid)
          setIncomingConnection(conn)
          conn.addListener("accept", updateUIAcceptedCall);
          conn.addListener("disconnect", disconnectedCall);
        }
      });

      //error
      device.on('error', (twilioError) => {
        console.log('twilioError', twilioError);

        twilioError.code === 31205 && setReconnecting(Math.random())
        twilioError.code === 31205 && device.destroy()
      });

      //incoming
      device.on('incoming', function (conn) {
        getCallerInfo(conn.parameters.CallSid)
        setIncomingConnection(conn)
      });

      //cancel
      device.on('cancel', function (conn) {
        console.log('CANCEL');
        setCanceledCall(conn.parameters.CallSid)
      });

      // disconnect
      device.on('disconnect', function (conn) {
        console.log('DISCONNECT', conn);
        setCanceledCall(conn.parameters.CallSid)
      });


      device.on('hold', function (conn) {
        // setCanceledCall(conn.parameters.CallSid)
      });
    }

    if (device && phoneCallStatus === 'Outgoing Only') {
      //incoming
      device.on('incoming', function (conn) {
        conn.reject()
      })
    }

    if (device && phoneCallStatus === 'Not Available') {
      setDevice(null)
      setToken('')
      device.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, device, phoneCallStatus])

  function handleAnswer(id: string) {
    connectCall[id].accept();

    let updateCalls = callsData.map(item => {
      if (item.current_call.caller_sid === id) {
        return {
          ...item,
          current_call: {
            ...item.current_call,
            status: 'active'
          }
        }
      } else {
        return item
      }
    })

    setCallsData(updateCalls)
  }

  function handleRejectCall(id: string) {
    connectCall[id].disconnect();
    let updateConnectCall = { ...connectCall }
    delete updateConnectCall[id]

    let updateCalls = callsData.map(item => {
      if (item.current_call.caller_sid === id) {
        return {
          ...item,
          current_call: {
            ...item.current_call,
            status: 'canceled'
          }
        }
      } else {
        return item
      }
    })

    setConnectCall(updateConnectCall)
    setCallsData(updateCalls)
  }

  function handleStopTalk(id: string) {
    connectCall[id].disconnect();
    let updateConnectCall = { ...connectCall }
    delete updateConnectCall[id]

    setTimeout(async () => {
      let getUpdateCaller = await updateCallerInfo(id)

      let updateCalls = callsData.map(item => {
        if (item.current_call.caller_sid === id && getUpdateCaller && getUpdateCaller.current_call) {
          return {
            ...getUpdateCaller,
            current_call: {
              ...getUpdateCaller.current_call,
              caller_sid: id,
              status: 'completed'
            }
          }
        } else {
          return item
        }
      })
      setCallsData(updateCalls)
    }, 100)

    let updateCalls = callsData.map(item => {
      if (item.current_call.caller_sid === id) {
        return {
          ...item,
          current_call: {
            ...item.current_call,
            status: 'completed'
          }
        }
      } else {
        return item
      }
    })
    setCallsData(updateCalls)
    setConnectCall(updateConnectCall)
  }

  function handleRemoveCompletedCall(id: string) {
    let updateCalls = callsData.filter(item => item.current_call.caller_sid !== id)

    setCallsData(updateCalls)
  }

  // MAKE AN OUTGOING CALL

  async function handleOutgoingCall(to: string, phone_id: string) {
    const params = {
      // get the phone number to call from the DOM
      to: to,
      phone_id: phone_id
    };

    if (device) {
      device.connect(params);
    } else {
      // Unable to make call
    }
  }

  function updateUIAcceptedCall() {
    let updateCalls = callsData.map(item => {
      if (item.current_call.status === 'Outgoing Call') {
        return {
          ...item,
          current_call: {
            ...item.current_call,
            status: 'active'
          }
        }
      } else {
        return item
      }
    })

    setCallsData(updateCalls)
  }

  function disconnectedCall(id: string) {
    connectCall[id].disconnect();
    let updateConnectCall = { ...connectCall }
    delete updateConnectCall[id]

    let updateCalls = callsData.map(item => {
      if (item.current_call.caller_sid === id) {
        return {
          ...item,
          current_call: {
            ...item.current_call,
            status: 'canceled'
          }
        }
      } else {
        return item
      }
    })

    setConnectCall(updateConnectCall)
    setCallsData(updateCalls)
  }

  async function handleCallExtension(extension: string) {
    if (!phoneCall) {
      dispatch(setPhoneCall(true))
    }
    const params = {
      // get the phone number to call from the DOM
      extension: extension
    };

    if (device) {
      device.connect(params);
    } else {
      // Unable to make call
    }
  }

  interface GetNotifyDataReport {
    success: boolean
    data: {
      website_requests: {
        today: number,
        yesterday: number,
        weekend: number,
      },
      payout_dispatchers?: {
        need_confirmation?: number,
        need_check?: number,
      },
      payout_staff?: {
        need_check?: number,
        need_confirmation?: number,
      },
    }
  }

  useEffect(() => {
    if (agreeSound) {

      if (
        lastNotify.today < notify.today ||
        lastNotify.weekend < notify.weekend ||
        lastNotify.yesterday < notify.yesterday ||
        lastNotify.need_confirmation < notify.need_confirmation ||
        lastNotify.need_check < notify.need_check ||
        lastNotify.staff_need_check < notify.staff_need_check ||
        lastNotify.staff_need_confirmation < notify.staff_need_confirmation
      ) {
        setIsBell(Math.random())
        setLastNotify({
          ...notify
        })
      } else if (
        lastNotify.today !== notify.today ||
        lastNotify.weekend !== notify.weekend ||
        lastNotify.yesterday !== notify.yesterday ||
        lastNotify.need_confirmation !== notify.need_confirmation ||
        lastNotify.need_check !== notify.need_check ||
        lastNotify.staff_need_check !== notify.staff_need_check ||
        lastNotify.staff_need_confirmation !== notify.staff_need_confirmation
      ) {
        setLastNotify({
          ...notify
        })
      }
    }

  }, [agreeSound, notify, lastNotify])

  async function getNotify() {
    // https://2022back4.artemiudintsev.com/api/users/notify?account_id=88888
    try {
      if (statusNetwork) {
        httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

        const { data: { data: notifyData, success } } = await httpClientUpdate.get('/users/notify',
          {
            params: {
              account_id: activeAccountId,
            }
          }) as { data: GetNotifyDataReport }
        if (success) {
          setNotify({
            today: notifyData.website_requests.today ? notifyData.website_requests.today : 0,
            yesterday: notifyData.website_requests.yesterday ? notifyData.website_requests.yesterday : 0,
            weekend: notifyData.website_requests.weekend ? notifyData.website_requests.weekend : 0,
            need_confirmation: notifyData?.payout_dispatchers?.need_confirmation ? notifyData.payout_dispatchers.need_confirmation : 0,
            need_check: notifyData?.payout_dispatchers?.need_check ? notifyData.payout_dispatchers.need_check : 0,
            staff_need_confirmation: notifyData?.payout_staff?.need_confirmation ? notifyData.payout_staff.need_confirmation : 0,
            staff_need_check: notifyData?.payout_staff?.need_check ? notifyData.payout_staff.need_check : 0,
          })
        } else {
          setNotify({
            today: 0,
            yesterday: 0,
            weekend: 0,
            need_confirmation: 0,
            need_check: 0,
            staff_need_confirmation: 0,
            staff_need_check: 0,
          })
        }
      }
    } catch (error) {
      setNotify({
        today: 0,
        yesterday: 0,
        weekend: 0,
        need_confirmation: 0,
        need_check: 0,
        staff_need_confirmation: 0,
        staff_need_check: 0,
      })
    }
  }

  useEffect(() => {
    let notifyInterval: NodeJS.Timeout
    if (activeAccountId && notifications_allow) {

      let getTime = localStorage.getItem('last_updated')

      if (!getTime) {
        localStorage.setItem('last_updated', moment().format('YYYY-MM-DD hh:mm:ss'))
        getNotify()
      } else {
        getNotify()
      }

      notifyInterval = setInterval(() => {
        const a = moment();
        const b = moment(localStorage.getItem('last_updated'));

        let duration = a.diff(b, 'seconds')

        if (duration >= Number(process.env.REACT_APP_INTERVAL_TIME_NOTIFY)) {
          getNotify()
          localStorage.setItem('last_updated', moment().format('YYYY-MM-DD hh:mm:ss'))
        }

      }, Number(process.env.REACT_APP_INTERVAL_TIME_NOTIFY) * 1000)

      return (() => {
        clearInterval(notifyInterval)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId, notifications_allow, agreeSound])

  // Render function
  return (<>

    {
      user && activeAccountId &&
      <Audio
        $player={$player}
      />
    }

    { /* Header */}
    {user && $router.route.name !== "auth" && activeAccountId &&
      <Header
        navActive={navActive}
        activeNavButton={activeNavButton}
        setActiveNavButton={setActiveNavButton}
        navMode={navMode}
        setSearchPanelExpanded={setSearchPanelExpanded}
        searchPanelExpanded={searchPanelExpanded}
        togglePhoneCall={togglePhoneCall}
        phoneCallStatus={phoneCallStatus}
        notify={notify}
        handleBell={handleBell}
        agreeSound={agreeSound}
        twilio_allow={twilio_allow}
        setIsCloseBefore={setIsCloseBefore}
      />}

    { /* Main block */}
    <main className={classNames({ 
      _authPage: $router.route.name === 'auth',
      _is_disabled_header: $router.route.name === 'technician_job',
   })}>

      { /* Aside panel */}
      {user && $router.route.name !== "auth" && activeAccountId &&
        <AsideNavPanel
          channels={channels}
          menus={menus}
          navActive={navActive}
          navMode={navMode}
          isNavItemAllowed={isNavItemAllowed}
          onNavSwitchNotchLeft={onNavSwitchNotchLeft}
          activeAccountId={activeAccountId}
          setUpdated={setUpdated}
        />}

      { /* Section */}
      <section className={classNames('pageSection', { __withNav: navActive.is })}>

        { /* Pages */}
        {$router.route.name === "auth" && <AuthPage />}
        {$router.route.name === "404" && <Page404 />}
        {$router.route.name === "500" && <Page500 />}
        {$router.route.name === "403" && <Page403 />}
        {$router.route.name === "401" && <Page401 />}

        {user && $router.route.name !== "auth" && activeAccountId &&
          <Main
            handleCallExtension={handleCallExtension}
            softphone_call_extension={permissions.softphone_call_extension}
            updated={updated}
            selectedCallCenter={selectedCallCenter}
          />}

        { /* Page darker */}
        {user && $router.route.name !== "auth" && activeAccountId ? (
          <div
            onClick={() => setActiveNavButton(null)}
            className={classNames(["darker", { _shown: activeNavButton }])}
          />
        ) : null}
      </section>
      {/* Phone section */}
      {
        user && $router.route.name !== "auth" && activeAccountId && phoneCall &&
        <OnlineCall
          togglePhoneCall={togglePhoneCall}
          handleAnswer={handleAnswer}
          handleRejectCall={handleRejectCall}
          disconnectedCall={disconnectedCall}
          handleStopTalk={handleStopTalk}
          handleOutgoingCall={handleOutgoingCall}
          callsData={callsData}
          phoneCallStatus={phoneCallStatus}
          handleRemoveCompletedCall={handleRemoveCompletedCall}
          handleAddComment={handleAddComment}
          permissions={permissions}
          handleCallExtension={handleCallExtension}
          device={device}
          setSelectedCallCenter={setSelectedCallCenter}
          selectedCallCenter={selectedCallCenter}
          dispatcherTwillio={dispatcherTwillio}
          handleRedirectCall={handleRedirectCall}
          handlePauseCall={handlePauseCall}
          handleResumeCall={handleResumeCall}
        />

      }

      { /* Nav switch notch */}
      {user && $router.route.name !== "auth" && activeAccountId ? (
        <button
          className="_iconed _zeroed nav-switch-notch"
          onMouseOver={() => onNavSwitchNotchHover()}
          onFocus={() => void 0}
        >
          <Icon icon="_notch" viewBox="0 0 10.34 29.25" />
        </button>
      ) : null}

      {/* Mobile menu container */}
      <Transition in={![null, "mobileAccountMenu"].includes(activeNavButton)} timeout={210} mountOnEnter={true} unmountOnExit={true}>
        {(state) => (
          <div className={classNames("mobile-menu-container", `transition-fade-${state}`)}>
            {activeNavButton === "helpMenu" ? <HelpMenu companyId={activeAccountId} setActiveNavButton={setActiveNavButton} /> : null}
            {activeNavButton === "notificationsWidget" ?
              <NotificationsWidget
                notify={notify}
                setActiveNavButton={setActiveNavButton}
                agreeSound={agreeSound}
                handleBell={handleBell}
              /> : null}
          </div>
        )}
      </Transition>
    </main>
  </>)
}

export default App
