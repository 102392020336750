import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, getDateTimeForInterface, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from "../../store/hooks"

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'service_resource'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AdditionalTimeReport
}

export interface AdditionalTimeReport {
  interface: {
    filter_words: {
      service_resource: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date,

    date_schedule_end: Date,
    date_schedule_start: Date,
  },

  permissions: {
    additional_time_service_resource_show: boolean,
    additional_time_service_resource_add: boolean,
    additional_time_service_resource_edit: boolean,
    additional_time_service_resource_delete: boolean,
  },

  edit: {
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
      time_zone: string
    }[]
  }

  additional_times: AdditionalTimes[]
}

interface AdditionalTimes {
  additional_time_end: string
  additional_time_id: string
  additional_time_start: string
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
  service_resource_code: number
  service_resource_id: string
  service_resource_nickname: string
  service_resource_time_zone: string
}

function AbsencesPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const timeZone = user?.time_zone

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())
  const [reportData, setReportData] = useState<AdditionalTimeReport | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.additional_time?.filter_field ? {
      service_resource: [] as string[],
      ...cookiesSetting.additional_time.filter_field,
    } : {
      service_resource: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.additional_time?.sort_field ? cookiesSetting.additional_time.sort_field : 'created_at',
      direction: cookiesSetting && cookiesSetting?.additional_time?.sort_type ? cookiesSetting.additional_time.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Service Resource',
      value: 'service_resource_nickname'
    }, {
      span: 'Start',
      value: 'additional_time_start'
    }, {
      span: 'End',
      value: 'additional_time_end'
    }]

  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      dateRangeCalendarShown: false,
      dateRangeType: "schedule" as "created" | "schedule",
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.additional_time?.filter_field ? {
        service_resource: [] as string[],
        ...cookiesSetting.additional_time.filter_field,
      } : {
        service_resource: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.additional_time?.sort_field ? cookiesSetting.additional_time.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.additional_time?.sort_type ? cookiesSetting.additional_time.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    if (!updated) {
      if (localInterface.dateRangePreset === 'all') {
        if (localInterface.dateRangeType === 'schedule') {
          setLocalInterface({
            ...localInterface,
            ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
          })
        } else {
          setLocalInterface({
            ...localInterface,
            ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
          })
        }
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'all') {
      if (localInterface.dateRangeType === 'schedule') {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
        })
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangeType])

  // Load absences function
  async function loadReport() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          date_type: $router.router.getState().params.localInterface.dateRangeType,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          date_type: localInterface.dateRangeType,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, additional_time: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.additional_time?.limit_rows ? cookiesSetting?.additional_time.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/additional-times/report?account_id=88888&page=1&limit_rows=100
      const { data: { data: responseReport, success, error } } = (await httpClientUpdate.get('/additional-times/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.additional_time?.limit_rows ? cookiesSetting?.additional_time?.limit_rows : reportsMaxRows,
          ...activeFilters,
          ...filterParams,
        }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        setReportData({
          ...responseReport,

          interface: {
            ...responseReport.interface,

            filter_words: {
              ...localInterface.filter_words,
              ...responseReport.interface.filter_words
            },

            min_date: getDateTimeForInterface(responseReport.interface.min_date),
            max_date: getDateTimeForInterface(responseReport.interface.max_date),
            date_schedule_start: getDateTimeForInterface(responseReport.interface.date_schedule_start),
            date_schedule_end: getDateTimeForInterface(responseReport.interface.date_schedule_end),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, additional_time: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absences on page mount
  useEffect(() => {

    loadReport()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getServiceResourceName(id: string) {
    let name = ''

    reportData && reportData.edit.service_resources.forEach(item => {
      if (item.service_resource_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="AbsencesPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Additional Time</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangeType={localInterface.dateRangeType}
          onDateRangeTypeChange={(value) => setLocalInterface({ ...localInterface, dateRangeType: value as any, page: 1 })}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.additional_time?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, additional_time: { ...cookiesSetting.additional_time, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.additional_time_service_resource_add ?
              (
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('additional-time.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    })}
                  >
                    <Icon icon="plus-thin" />
                    <span>Add Additional Time</span>
                    <Icon icon="time-16" />
                  </button>
                </div>
              ) : (<></>)
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Service Resource</span>)}

                  allFiltersSelected={isAllFiltersSelected("service_resource")}
                  onAllFiltersChange={(value) => switchFilter("service_resource", "All", value)}

                  filterWords={reportData?.interface.filter_words.service_resource.map((filterWord) => ({
                    word: filterWord,
                    word_name: getServiceResourceName(filterWord),
                    selected: isFilterSelected("service_resource", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("service_resource", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'service_resource_nickname' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'service_resource_nickname', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Start</span>)}

                  sortDirection={localInterface.sort.field === 'additional_time_start' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'additional_time_start', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>End</span>)}

                  sortDirection={localInterface.sort.field === 'additional_time_end' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'additional_time_end', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.additional_times.map((time, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={
                  reportData.permissions.additional_time_service_resource_show &&
                    time.additional_time_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.additional_time_service_resource_show &&
                  time.additional_time_id &&
                  $router.router.navigate('additional-time.item', {
                    companyId: activeAccountId,
                    additionalTimeId: time.additional_time_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.additional_time_service_resource_show &&
                  time.additional_time_id &&
                  handleChangeMenu(event, time.additional_time_id, 'additional', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.created_at)}</td>

                <td> {time.service_resource_code ? `${time.service_resource_nickname} (${time.service_resource_code})` : time.service_resource_nickname} </td>

                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_start)}</td>

                <td>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_end)}
                  {
                    reportData.permissions.additional_time_service_resource_show &&
                    showContext === `additional_${time.additional_time_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('additional-time.item', {
                            companyId: activeAccountId,
                            additionalTimeId: time.additional_time_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/additional-time/${time.additional_time_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium desktop table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Service resource</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>

            {reportData.additional_times.map((time, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={
                  reportData.permissions.additional_time_service_resource_show &&
                    time.additional_time_id
                    ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.additional_time_service_resource_show &&
                  time.additional_time_id &&
                  $router.router.navigate('additional-time.item', {
                    companyId: activeAccountId,
                    additionalTimeId: time.additional_time_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  time.additional_time_id &&
                  reportData.permissions.additional_time_service_resource_show &&
                  handleChangeMenu(event, time.additional_time_id, 'additional', i)}
              >
                <td>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.created_at)}
                </td>

                <td>
                  {time.service_resource_code ? `${time.service_resource_nickname} (${time.service_resource_code})` : time.service_resource_nickname}
                </td>

                <td>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_start)}
                </td>

                <td>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_end)}

                  {
                    reportData.permissions.additional_time_service_resource_show &&
                    showContext === `additional_${time.additional_time_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('additional-time.item', {
                            companyId: activeAccountId,
                            additionalTimeId: time.additional_time_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/additional-time/${time.additional_time_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.additional_times.map((time, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.additional_time_service_resource_show &&
                  time.additional_time_id &&
                  $router.router.navigate('additional-time.item', {
                    companyId: activeAccountId,
                    additionalTimeId: time.additional_time_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >

                <div className="__top">

                  <div className="__left">
                    <span className="gray">Service resource:</span>
                    <span> {time.service_resource_code ? `${time.service_resource_nickname} (${time.service_resource_code})` : time.service_resource_nickname}</span>
                  </div>

                  <div className="__right">
                    <span className="gray">Date:</span>
                    <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.created_at as string)}</span>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <span className="gray">Start:</span>
                    <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_start as string)}</span>
                  </div>

                  <div className="__right small">
                    <span className="gray">End:</span>
                    <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', time.service_resource_time_zone, time.additional_time_end as string)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.additional_time?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, additional_time: { ...cookiesSetting.additional_time, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null
    }
  </>)
}

export default AbsencesPage_List