/* eslint-disable jsx-a11y/iframe-has-title */
import { memo } from 'react'
import classNames from "classnames"

import Select from '../../components/Select'

import HouseImage from '../../images/house-sample.jpg'

interface BookingLeftSideProps {
  selectedAddress: string,
  setSelectedAddress: (value: string) => void,
  addressOptions: string[]
  mobile?: boolean
}

function BookingLeftSide({
  selectedAddress,
  setSelectedAddress,
  addressOptions,
  mobile
}: BookingLeftSideProps) {
  return (
    <div className={
      classNames('booking_page_left', {
        mobile: mobile
      })
    }
    >
      <div className='block-location booking-location'>
        <div className='block-title'>
          Map Preview
        </div>

        <div className='block-address'>
          <Select options={addressOptions.map((address) => ({
            span: address,
            value: address
          }))} selectedOption={selectedAddress} onChange={(value: string) => setSelectedAddress(value)} />
        </div>

        <div className={
          classNames('block-map', 'booking_block-map', {
            mobile: mobile
          })
        }
        >
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14268.274546089124!2d-81.88672024389766!3d26.614246625837634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db41b541a87ca1%3A0xb25b25fe95027f76!2zMTIzNCBGbG9yaWRhIEF2ZSwgRm9ydCBNeWVycywgRkwgMzM5MDEsINCh0KjQkA!5e0!3m2!1sru!2sru!4v1581884786460!5m2!1sru!2sru">

          </iframe>
        </div>

        <div className='house-info booking_house-info'>
          <div className='house-img booking_house-img'>
            <img
              style={{
                objectFit: 'contain',
                width: '100%'
              }}
              src={HouseImage}
              alt="House_image"
            />
          </div>

          <div className='house-data'>
            <div className='house-table'>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p>Zestimate: $382,015</p>

                      <p>Built in 2013</p>
                    </th>

                    <th>
                      <p>3</p>
                      <p>Beds</p>
                    </th>

                    <th>
                      <p>3</p>
                      <p>Baths</p>
                    </th>

                    <th>
                      <p>2,453</p>
                      <p>Sq.ft.</p>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className='copyright'>© Zillow, Inc 2006-2020</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(BookingLeftSide)
