import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment"
import classNames from "classnames"
import qs from "qs"
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
import Job from "../../models/Job"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'status' | 'property_type' | 'area' | 'source' | 'created_by' | 'service_resource_id'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: JobsReport
}

interface JobsReport {
  interface: {
    filter_words: {
      status: string[],
      property_type: string[],
      area: string[],
      source: string[],
      created_by: string[],
      service_resource_id: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    job_add: boolean
    job_delete: boolean
    job_edit: boolean
    job_report_show: boolean
    job_show: boolean
  },

  dashboard: {

    all: string,
    closed: string,
    in_progress: string,
    canceled: string
  },

  jobs: Job[]

  edit: {
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
      time_zone: string
    }[]
  }
}

function JobsPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<JobsReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.jobs?.filter_field ? {
        source: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
        ...cookiesSetting.jobs.filter_field,
      } : {
        source: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.jobs?.sort_field ? cookiesSetting.jobs.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.jobs?.sort_type ? cookiesSetting.jobs.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Name',
        value: 'name'
      }, {
        span: 'Status',
        value: 'name'
      }, {
        span: 'Property type',
        value: 'property_type'
      }, {
        span: 'Area',
        value: 'area'
      }, {
        span: 'Source',
        value: 'source'
      }, {
        span: 'Status',
        value: 'status'
      }, {
        span: 'Created by',
        value: 'created_by'
      }, {
        span: 'Appointments',
        value: 'appointments'
      }, {
        span: 'Service resource',
        value: 'service_resource_names'
      }, {
        span: 'Total',
        value: 'total'
      }, {
        span: 'Paid',
        value: 'paid'
      }]

    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.jobs?.filter_field ? {
        source: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
        ...cookiesSetting.jobs.filter_field,
      } : {
        source: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.jobs?.sort_field ? cookiesSetting.jobs.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.jobs?.sort_type ? cookiesSetting.jobs.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load jobs function
  async function loadJobs() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])
    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.jobs?.limit_rows ? JSON.stringify(cookiesSetting?.jobs?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.jobs?.limit_rows ? JSON.stringify(cookiesSetting?.jobs?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, jobs: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.jobs?.limit_rows ? cookiesSetting?.jobs.limit_rows : reportsMaxRows } })
      }
    }
    try {
      const { data: { data: jobs, success, error } } = (await httpClientUpdate.post('/jobs/report', qs.stringify(filterParams), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        if (jobs.permissions.job_report_show) {
          setReportData({
            ...jobs,
            interface: {
              ...jobs.interface,
              min_date: moment(jobs.interface.min_date).startOf('day').toDate(),
              max_date: moment(jobs.interface.max_date).endOf('day').toDate(),
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, jobs: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load jobs on page mount
  useEffect(() => {
    loadJobs()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getServiceResourceName(id: string) {
    let name = ''

    reportData && reportData.edit.service_resources.forEach(item => {
      if (item.service_resource_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="JobsPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">

          <div className="cell">

            <div className="amount">{reportData.dashboard.all}</div>
            <div className="legend">All Jobs</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.closed}</div>
            <div className="legend">Closed</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.in_progress}</div>
            <div className="legend">In progress</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.canceled}</div>
            <div className="legend">Canceled</div>

          </div>
        </div>

        { /* Page header */}
        <div className="page-header">
          <h1>Jobs</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.jobs?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, jobs: { ...cookiesSetting.jobs, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Status</span>)}

                  allFiltersSelected={isAllFiltersSelected("status")}
                  onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                  filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("status", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("status", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Property type</span>)}

                  allFiltersSelected={isAllFiltersSelected("property_type")}
                  onAllFiltersChange={(value) => switchFilter("property_type", "All", value)}

                  filterWords={reportData.interface.filter_words.property_type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("property_type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("property_type", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'property_type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'property_type', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Area</span>)}

                  allFiltersSelected={isAllFiltersSelected("area")}
                  onAllFiltersChange={(value) => switchFilter("area", "All", value)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  filterWords={reportData.interface.filter_words.area.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("area", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("area", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                  filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Created by</span>)}

                  allFiltersSelected={isAllFiltersSelected("created_by")}
                  onAllFiltersChange={(value) => switchFilter("created_by", "All", value)}

                  filterWords={reportData.interface.filter_words.created_by.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("created_by", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("created_by", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'created_by' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_by', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Appointments</span>)}

                  sortDirection={localInterface.sort.field === 'appointments' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'appointments', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Service resource</span>)}

                  allFiltersSelected={isAllFiltersSelected("service_resource_id")}
                  onAllFiltersChange={(value) => switchFilter("service_resource_id", "All", value)}

                  filterWords={reportData.interface.filter_words.service_resource_id.map((filterWord) => ({
                    word: filterWord,
                    word_name: getServiceResourceName(filterWord),
                    selected: isFilterSelected("service_resource_id", filterWord)
                  }))}
                  onFilterChange={(value) => { switchFilter("service_resource_id", value.word, value.selected) }}

                  sortDirection={localInterface.sort.field === 'service_resource_names' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'service_resource_names', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Total</span>)}

                  sortDirection={localInterface.sort.field === 'total' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Paid</span>)}

                  sortDirection={localInterface.sort.field === 'paid' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'paid', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>
            {reportData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.job_show && job.job_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</td>
                <td>{job.name}</td>
                <td>
                  {
                    job.status === 'Canceled' ?
                      <span className="red">
                        {job.status}
                      </span> :
                      <span>
                        {job.status}
                      </span>
                  }
                </td>
                <td>{job.property_type}</td>
                <td>{job.area}</td>
                <td>{job.source}</td>
                <td>{job.created_by}</td>
                <td>{job.appointments}</td>
                <td> {job.service_resource_names}</td>
                <td>{job.total}</td>
                <td>
                  {job.paid}
                  {
                    reportData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobId: job.job_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medum screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.job_show && job.job_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>
                  <div>{job.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</div>
                </td>
                <td>
                  <div>{job.created_by}</div>
                  <div> {job.service_resource_names} </div>
                </td>
                <td>
                  <div>{job.area}</div>
                  <div>{job.source}</div>
                </td>
                <td>
                  <div>{job.property_type}</div>
                  <div>
                    {
                      job.status === 'Canceled' ?
                        <span className="red">
                          {job.status}
                        </span> :
                        <span>
                          {job.status}
                        </span>
                    }
                  </div>
                </td>
                <td>
                  <div>Appointments: {job.appointments}</div>
                  <div>&nbsp;</div>
                </td>
                <td>
                  <div>Total: {job.total}</div>
                  <div>Paid: {job.paid}</div>
                  {
                    reportData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobId: job.job_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.jobs.map((job, i: number) => (
              <div
                key={i}
                className="item"
                onClick={() =>
                  reportData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>
                      <strong>{job.name}</strong>
                    </div>
                    <div>
                      Appointments: {job.appointments}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Paid: <b>{job.paid}</b>
                    </div>
                    <div>
                      Total: <b>{job.total}</b>
                    </div>
                    <div>
                      {job.property_type}
                    </div>
                    <div>
                      <b>
                        {
                          job.status === 'Canceled' ?
                            <span className="red">
                              {job.status}
                            </span> :
                            <span>
                              {job.status}
                            </span>
                        }
                      </b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">

                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {job.area}</span>
                    </div>
                    <div>
                      <span className="gray">Source:</span>
                      <span> {job.source}</span>
                    </div>
                    <div>
                      <span className="gray">Service Resourse:</span>
                      <span> {job.service_resource_names}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}
                    </div>
                    <div>
                      Created by {job.created_by}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.jobs?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, jobs: { ...cookiesSetting.jobs, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default JobsPage_List
